import { useMemo } from 'react';

import { useCurrentSessionMode } from '../../Game/hooks';
import {
  usePlaybackDesc,
  usePlaybackInfoCurrentBlockBrand,
} from '../../Game/Playback/PlaybackInfoProvider';
import { JsonTable } from './JsonTable';

export function PlaybackDetails() {
  const brand = usePlaybackInfoCurrentBlockBrand();
  const mode = useCurrentSessionMode();
  const desc = usePlaybackDesc(mode);

  const obj = useMemo(() => {
    return { 'Current Block Brand Name': brand?.name ?? '(None)', ...desc };
  }, [brand?.name, desc]);

  return <JsonTable obj={obj} />;
}
