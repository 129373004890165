import { useMemo, useState } from 'react';

import {
  getDefaultFeatures,
  getRawFeatureQueryParam,
} from '../../../hooks/useFeatureQueryParam';

export function URLFeatures(): JSX.Element {
  const sectionClassName = '';
  const summaryClassName = 'text-white font-bold text-2xs bg-lp-gray-001 py-1';
  const detailsClassName = 'flex flex-col gap-1';

  const [selectedFeatures] = useState(
    () => new URLSearchParams(window.location.search)
  );

  const { multiples, singles } = useMemo(() => {
    const defaults = getDefaultFeatures();
    const features = Object.entries(defaults).sort((a, b) =>
      a[0].localeCompare(b[0])
    );

    const featureToggles: {
      [key: string]: JSX.Element[];
    } = {};

    for (const [n, defaultValue] of features) {
      const name = n as keyof typeof defaults;
      const current = getRawFeatureQueryParam(name);
      const parts = name?.split('-');

      // Note: for finer/broader groups, just decrease/increase the slice end.
      const groupName = parts?.slice(0, 2).join('-');

      if (!groupName) continue;

      const group = featureToggles[groupName] ?? [];
      featureToggles[groupName] = group;

      group.push(
        <label
          key={name}
          className='text-3xs font-light text-gray-200 hover:bg-lp-gray-006 flex justify-between items-center'
        >
          {name}{' '}
          {Array.isArray(defaultValue) ? (
            <select
              className='bg-transparent text-gray-50 h-4'
              style={{ textAlignLast: 'right' }}
              defaultValue={current ?? undefined}
              onChange={(e) => {
                const value = e.target.value;
                if (value === 'none') {
                  selectedFeatures.delete(name);
                } else {
                  selectedFeatures.set(name, e.target.value);
                }
              }}
            >
              <option className='bg-lp-black-001 text-gray-50' value='none'>
                (none)
              </option>
              {defaultValue.map((v) => (
                <option
                  className='bg-lp-black-001 text-gray-50'
                  value={v}
                  key={v}
                >
                  {v} {current === v && '(current)'}{' '}
                  {v === defaultValue[0] && '(default)'}
                </option>
              ))}
            </select>
          ) : typeof defaultValue === 'number' ? (
            <input
              type='text'
              className='bg-transparent text-gray-50 ring-1 ring-white text-right px-1 h-4 w-18'
              defaultValue={current ?? defaultValue}
              onChange={(e) => {
                const value = e.target.value;
                const parsed = parseInt(value, 10);
                if (value && !isNaN(parsed)) {
                  selectedFeatures.set(name, e.target.value);
                } else {
                  selectedFeatures.delete(name);
                }
              }}
            />
          ) : (
            <select
              className='bg-transparent text-gray-50 h-4'
              style={{ textAlignLast: 'right' }}
              defaultValue={current ?? undefined}
              onChange={(e) => {
                const value = e.target.value;
                if (value === 'none') {
                  selectedFeatures.delete(name);
                } else {
                  selectedFeatures.set(name, e.target.value);
                }
              }}
            >
              <option className='bg-lp-black-001 text-gray-50' value='none'>
                (none)
              </option>
              {defaultValue !== 'enabled' && defaultValue !== 'disabled' ? (
                <option
                  className='bg-lp-black-001 text-gray-50'
                  value={defaultValue}
                >
                  {defaultValue} {current === defaultValue && '(current)'}{' '}
                  (default)
                </option>
              ) : (
                <>
                  <option
                    className='bg-lp-black-001 text-gray-50'
                    value='disabled'
                  >
                    disabled {current === 'disabled' && '(current)'}
                  </option>
                  <option
                    className='bg-lp-black-001 text-gray-50'
                    value='enabled'
                  >
                    enabled {current === 'enabled' && '(current)'}
                  </option>
                </>
              )}
            </select>
          )}
        </label>
      );
    }

    const singles = [];
    const multiples = [];

    for (const [groupName, toggles] of Object.entries(featureToggles)) {
      if (toggles.length === 1) {
        singles.push(toggles[0]);
      } else {
        multiples.push(
          <div className={sectionClassName} key={multiples.length}>
            <header className={summaryClassName}>{groupName}</header>
            <div className={detailsClassName}>{toggles}</div>
          </div>
        );
      }
    }
    return { multiples, singles };
  }, [selectedFeatures]);

  return (
    <div className='flex flex-col gap-2'>
      <details>
        <summary className='text-2xs font-bold my-3 text-white '>
          <header className='inline'>URL Features</header>
        </summary>
        <div className={sectionClassName}>
          <header className={summaryClassName}>standalone feature flags</header>
          <div className={detailsClassName}>{singles}</div>
        </div>

        {multiples}

        <button
          type='button'
          className='btn-primary w-full my-3 px-10 h-7 text-sms'
          onClick={() => {
            window.location.search = selectedFeatures.toString();
          }}
        >
          Reload with Selected Options
        </button>
      </details>
    </div>
  );
}
