import React, {
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import config from '../../config';
import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useVideoElementFromMediaStream } from '../../hooks/useVideoElementFromMediaStream';
import { hasStaffFlag } from '../../types';
import { useCloneSingletonMediaStream } from '../Device';
import { useAmICohost, useMyTeamId } from '../Player';
import { useIsStreamSessionAlive } from '../Session';
import { usePrivacySettings } from '../Settings/usePrivacySettings';
import { useUser, useUserStates } from '../UserContext';
import { Detector, EmbeddedExecutor, WorkerExecutor } from './detector';
import { Filmstrip, Recorder } from './Recorder';
import { type Preview, ProfileIndex } from './type';
import { Uploader } from './uploader';

const sentimentDetectorMode = getFeatureQueryParamArray('sentiment-detector');
const sentimentDetectorWorkerEnabled = getFeatureQueryParam(
  'sentiment-detector-worker'
);
const sentimentDetectorInterval = getFeatureQueryParamNumber(
  'sentiment-detector-interval-ms'
);
const sentimentRecorderEnabled = getFeatureQueryParam('sentiment-recorder');
const sentimentRecordingProfileIndex =
  ProfileIndex[getFeatureQueryParamArray('sentiment-recording-profile')];
const sentimentBufferTechnique = getFeatureQueryParamArray('sentiment-buffer');
const sentimentBufferWillReadFrequently = getFeatureQueryParam(
  'sentiment-buffer-will-read-frequently'
);

export function SentimentDetectorLauncher(): JSX.Element | null {
  const { detector } = useSentimentContext();
  const sessionAlive = useIsStreamSessionAlive();
  const { video: hasVideo } = useUserStates();
  const { privacySettings, isLoading } = usePrivacySettings();
  const myTeamId = useMyTeamId();
  const username = useUser().username;
  const isCohost = useAmICohost();

  const allowSentimentRecording = useMemo(() => {
    // don't run the sentiment recorder if the user is not on a team, for example they are marked as away.
    if (!myTeamId) return false;

    if (hasStaffFlag(username) || isCohost) return false;

    // note: if we're loading the settings, we return false.
    // otherwise, disable the recorder if the value is present at set to false.
    if (
      (isLoading && privacySettings === undefined) ||
      privacySettings?.value?.allowJoyCaptures === false
    ) {
      return false;
    }

    // otherwise allow sentiment recording
    return true;
  }, [isCohost, isLoading, myTeamId, privacySettings, username]);

  const detectorEnabled = useMemo<boolean>(() => {
    if (sentimentDetectorMode === 'disabled') return false;
    if (sentimentDetectorMode === 'always') return true;
    if (!allowSentimentRecording) return false;
    return sessionAlive;
  }, [sessionAlive, allowSentimentRecording]);

  const filmstrip = useInstance(
    () =>
      new Filmstrip(
        sentimentRecordingProfileIndex,
        sentimentBufferTechnique,
        sentimentBufferWillReadFrequently
      )
  );

  useEffect(() => {
    return () => {
      filmstrip.close();
    };
  }, [filmstrip]);

  useEffect(() => {
    if (detectorEnabled) {
      detector.start(sentimentDetectorInterval);
    } else {
      detector.stop('disabled');
    }
    return () => {
      detector.stop();
    };
  }, [detector, detectorEnabled]);

  useEffect(() => {
    if (!detectorEnabled) return;
    detector.pause(!hasVideo);
  }, [detector, hasVideo, detectorEnabled]);

  if (!sentimentRecorderEnabled || !allowSentimentRecording) return null;

  return (
    <Recorder
      filmstrip={filmstrip}
      pause={!hasVideo}
      cooldown={config.sentiment.cooldown}
      upload={config.sentiment.uploadEnabled}
      uploadLimits={config.sentiment.uploadLimits}
      baselineCapture={config.sentiment.baselineCapture}
    />
  );
}

interface SentimentContext {
  video: HTMLVideoElement;
  detector: Detector;
  uploader: Uploader;
  preview: Preview | null;
  setPreview: (preview: Preview | null) => void;
}

const Context = React.createContext<SentimentContext>({} as SentimentContext);

export const useSentimentContext = (): SentimentContext => {
  return useContext(Context);
};

export const SentimentProvider = (props: {
  children?: ReactNode;
}): JSX.Element => {
  const [preview, setPreview] = useState<Preview | null>(null);
  const localStream = useCloneSingletonMediaStream();
  const video = useVideoElementFromMediaStream(localStream);
  const detector = useMemo(() => {
    const executor = sentimentDetectorWorkerEnabled
      ? new WorkerExecutor(video)
      : new EmbeddedExecutor(video);
    return new Detector(executor);
  }, [video]);
  const uploader = useMemo(() => new Uploader(), []);

  useEffect(() => {
    return () => {
      detector.executor.close();
    };
  }, [detector.executor]);

  const ctxValue = useMemo(
    () => ({
      video,
      detector,
      uploader,
      preview,
      setPreview,
    }),
    [detector, preview, uploader, video]
  );
  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
};
