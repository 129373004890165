import { useEffect, useMemo, useRef, useState } from 'react';
import { type IntercomProps, useIntercom } from 'react-use-intercom';

import { useMyInstance } from '../../hooks/useMyInstance';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { type Participant } from '../../types/user';
import { getStaticAssetPath } from '../../utils/assets';
import { QuestionIcon } from '../icons/QuestionIcon';
import { useMyOrganization } from '../Organization';
import { useParticipantsAsArray } from '../Player';
import { useUser } from '../UserContext';
import { useVenueId, useVenueSlug } from '../Venue/VenueProvider';

const SUPPORT_ONLINE_IMG = getStaticAssetPath('images/help-headphone.png');

function Control(props: {
  me: Participant;
  showIntercom: boolean;
  setShowIntercom: (val: boolean) => void;
  className: string;
}): JSX.Element {
  const { me, showIntercom, setShowIntercom, className } = props;
  const [inited, setInited] = useState(false);
  const venueId = useVenueId();
  const venueName = useVenueSlug();
  const ref = useRef<HTMLDivElement>(null);
  const { boot, show, hide, update, shutdown } = useIntercom();
  const handleIntercom = () => {
    setShowIntercom(!showIntercom);
  };
  const user = useUser();
  const organization = useMyOrganization();
  const spectators = useParticipantsAsArray({
    filters: [
      'status:connected',
      'host:false',
      'cohost:false',
      'staff:true',
      'spectator:true',
    ],
  });
  const orgSize = organization?.maxSize;
  const subscriptionProduct = organization?.subscription.productName;

  const intercomCustomAttributes = useMemo(() => {
    const intercomAttributes: IntercomProps = {
      userId: me.id,
      name: me.username,
      email: user.email || '',
      customAttributes: {
        userId: me.id,
        userName: me.username,
        teamId: me.teamId || '',
        venueId: venueId,
        venueURL: window.location.href,
        venueName: venueName,
        'Org Size': orgSize ?? 'N/A',
        'Subscription Product': subscriptionProduct ?? 'N/A',
      },
    };

    if (organization?.name && organization.id) {
      intercomAttributes.company = {
        companyId: organization?.id,
        name: organization.name,
      };
    }

    return intercomAttributes;
  }, [
    me.id,
    me.teamId,
    me.username,
    orgSize,
    organization?.id,
    organization?.name,
    subscriptionProduct,
    user.email,
    venueId,
    venueName,
  ]);

  const rebootIntercom = () => {
    const intercomAttributes: IntercomProps = {
      hideDefaultLauncher: true,
      alignment: 'left',
      horizontalPadding: 60,
      ...intercomCustomAttributes,
    };

    boot(intercomAttributes);
  };

  // init once, ingore the custom attributes changes like username
  useEffect(() => {
    boot({
      hideDefaultLauncher: true,
      alignment: 'left',
      horizontalPadding: 60,
    });
    setInited(true);
    return () => setInited(false);
  }, [boot]);

  useEffect(() => {
    update(intercomCustomAttributes);
  }, [intercomCustomAttributes, update]);

  useEffect(() => {
    if (showIntercom) {
      show();
    } else {
      hide();
    }
  }, [hide, show, showIntercom]);

  useOutsideClick(ref, () => {
    const app = document.querySelector<HTMLElement>('.intercom-app');
    const borderless = document.querySelector<HTMLElement>(
      '.intercom-borderless-frame'
    );
    if (app) {
      setShowIntercom(false);
    }
    if (borderless) {
      shutdown();
      rebootIntercom();
    }
  });

  return (
    <div ref={ref} className={`${className} group`}>
      <button
        type='button'
        className={`icon-btn relative flex flex-col`}
        onClick={handleIntercom}
        disabled={!inited}
      >
        {spectators.length > 0 ? (
          <>
            <link rel='preload' as='image' href={SUPPORT_ONLINE_IMG} />
            <img src={SUPPORT_ONLINE_IMG} alt='help' className='w-7 h-7' />
            <div
              className='font-black text-red-006'
              style={{ fontSize: '7px' }}
            >
              HELP
            </div>
          </>
        ) : (
          <QuestionIcon />
        )}
      </button>
      {spectators.length > 0 && (
        <div className='absolute hidden group-hover:flex flex-col top-0 right-0 transform-gpu translate-x-full'>
          <div className='flex flex-col bg-dark-gray rounded-lg px-3 py-2 ml-1'>
            <div className='text-tertiary font-bold whitespace-nowrap'>
              Live Support is here to help!
            </div>
            <div className='text-sms text-white'>
              We're watching the game for better service. Click to chat with us!
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export function IntercomControl(props: {
  className: string;
}): JSX.Element | null {
  const { className } = props;
  const [showIntercom, setShowIntercom] = useState(false);
  const me = useMyInstance();
  if (!me) return null;
  return (
    <Control
      me={me}
      showIntercom={showIntercom}
      setShowIntercom={setShowIntercom}
      className={className}
    />
  );
}
