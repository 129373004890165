const CircleAddIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3 h-3 fill-current'}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='6' cy='6' r='6' fill='#C4C4C4' />
      <path
        d='M5.24153 9H6.75847V6.75847H9V5.24153H6.75847V3H5.24153V5.24153H3V6.75847H5.24153V9Z'
        fill='black'
      />
    </svg>
  );
};

export { CircleAddIcon };
