import { useClearVenueEvent, useVenueEvent } from '../../Venue';
import { JsonTable } from './JsonTable';

export function ClearVenueEventControl(): JSX.Element {
  const clearVenueEvent = useClearVenueEvent();
  const handleClick = async () => {
    await clearVenueEvent();
  };
  return (
    <button
      type='button'
      className='btn btn-primary w-full h-7 text-sms'
      onClick={handleClick}
    >
      Clear Venue Event
    </button>
  );
}

export function VenueEventTools() {
  const venueEvent = useVenueEvent();
  if (!venueEvent) return null;
  return (
    <div>
      <JsonTable obj={venueEvent} />
      <div className='mt-2'>
        <ClearVenueEventControl />
      </div>
    </div>
  );
}
