import { useMemo, useRef } from 'react';

import { type Logger } from '@lp-lib/logger-base';

import {
  getFeatureQueryParam,
  getRawFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { useInterval } from '../../hooks/useInterval';
import { useMyInstance } from '../../hooks/useMyInstance';
import { isNotStaff } from '../../types';
import { ValtioUtils } from '../../utils/valtio';
import { useIsFirebaseConnected } from '../Firebase';
import {
  useGameHostingAPI,
  useGameHostingCoordinatorActive,
} from '../Game/GameHostingProvider';
import { useParticipantsAsArrayGetter } from '../Player';
import { useUserStates } from '../UserContext';
import { useVenue, useVenueOwner } from '../Venue/VenueProvider';
import { selectCandidateCoordinator } from './OnDCoordinatorSelectPolicy';
import { type CoordinatorSelectPolicy } from './types';

function actAsGameController(log: Logger): boolean {
  const rawValue = getRawFeatureQueryParam('cloud-hosting');
  // 1. The override parameter always has higher priority
  if (rawValue) {
    const enabled = rawValue === 'enabled';
    log.info('use cloud controller (override parameter)', { enabled });
    return !enabled; // use cloud hosting (controller)
  }
  // 2. hand over the decision making to the default feature parameter
  const defaultValue = getFeatureQueryParam('cloud-hosting');
  log.info('use cloud controller (default feature parameter)', {
    enabled: defaultValue,
  });
  return !defaultValue;
}

export function useOnDCoordinatorSelectPolicy(
  log: Logger
): CoordinatorSelectPolicy {
  const [venue] = useVenue();
  const venueOwner = useVenueOwner();
  return useMemo(() => {
    log.debug('resolve coordinator select policy', {
      venue: ValtioUtils.detachCopy(venue),
      venueOwner: ValtioUtils.detachCopy(venueOwner),
    });
    if (venue.coordinatorPolicyOverride) {
      log.debug('using venue coordinator policy override', {
        policy: venue.coordinatorPolicyOverride,
      });
      return venue.coordinatorPolicyOverride;
    }

    if (!venueOwner.orgId) {
      return 'venue-owner';
    } else {
      return venue.shareControl ? 'everyone' : 'venue-owner';
    }
  }, [log, venue, venueOwner]);
}

export function useInitOnDGameHosting(): void {
  const { joined } = useUserStates();
  const api = useGameHostingAPI();
  const policy = useOnDCoordinatorSelectPolicy(api.log);
  const venueOwner = useVenueOwner();
  const getParticipants = useParticipantsAsArrayGetter();
  const [venue] = useVenue();
  const connected = useIsFirebaseConnected();
  const me = useMyInstance();
  const hasCoordinator = useGameHostingCoordinatorActive();
  const policyAtRegisterTime = useRef(policy);
  const candidateHash = useRef<string | null>(null);

  const register = async () => {
    policyAtRegisterTime.current = policy;
    const participants = getParticipants({
      // NOTE: "cohost" is allowed to control the game, but not a host client.
      filters: ['host:false', 'status:connected', 'team:true'],
    });

    const next = selectCandidateCoordinator(
      policy,
      { id: venue.id, uid: venue.uid },
      // include the venueOwner anyway (even if they have the staff emoji)
      participants.filter((p) => venueOwner.id === p.id || isNotStaff(p))
    );
    const hash = `${policy}:${next?.clientId ?? 'null'}`;
    if (hash !== candidateHash.current) {
      api.log.info('coordinator candidate', {
        policy,
        candidate: next ?? null,
      });
    }
    candidateHash.current = hash;
    if (!next || next.clientId !== me?.clientId) return;
    await api.register(next.clientId, {
      asCoordinator: true,
      asController: actAsGameController(api.log),
    });
  };

  const meIsReady = joined && connected && me?.id;

  // If the policy changes to org/venue owner, but the coordinator is still
  // stable, we likely want to revoke that coordinator to be re-assigned to the
  // owner.
  const policyHasChanged =
    policy !== policyAtRegisterTime.current && policy !== 'everyone';

  const needsCoordinator = meIsReady && (!hasCoordinator || policyHasChanged);

  useInterval(register, needsCoordinator ? 250 : null);
}
