export function PushPinIcon({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 490.125 490.125'
    >
      <path d='M300.625 5.025c-6.7-6.7-17.6-6.7-24.3 0l-72.6 72.6c-6.7 6.7-6.7 17.6 0 24.3l16.3 16.3-40.3 40.3-63.5-7c-3-.3-6-.5-8.9-.5-21.7 0-42.2 8.5-57.5 23.8l-20.8 20.8c-6.7 6.7-6.7 17.6 0 24.3l108.5 108.5-132.4 132.4c-6.7 6.7-6.7 17.6 0 24.3 3.3 3.3 7.7 5 12.1 5s8.8-1.7 12.1-5l132.5-132.5 108.5 108.5c3.3 3.3 7.7 5 12.1 5s8.8-1.7 12.1-5l20.8-20.8c17.6-17.6 26.1-41.8 23.3-66.4l-7-63.5 40.3-40.3 16.2 16.2c6.7 6.7 17.6 6.7 24.3 0l72.6-72.6c3.2-3.2 5-7.6 5-12.1s-1.8-8.9-5-12.1l-184.4-184.5zm99.8 245l-16.2-16.3c-6.4-6.4-17.8-6.4-24.3 0l-58.2 58.3c-3.7 3.7-5.5 8.8-4.9 14l7.9 71.6c1.6 14.3-3.3 28.3-13.5 38.4l-8.7 8.7-217.1-217.1 8.7-8.6c10.1-10.1 24.2-15 38.4-13.5l71.7 7.9c5.2.6 10.3-1.2 14-4.9l58.2-58.2c6.7-6.7 6.7-17.6 0-24.3l-16.3-16.3 48.3-48.3 160.3 160.3-48.3 48.3z'></path>
    </svg>
  );
}
