import { useCallback, useEffect, useState } from 'react';

import { useHostClientId } from '../../Player';
import { useRTCService } from '../../WebRTC';

export function HostAudioRecordButton(): JSX.Element | null {
  const stage = useRTCService('stage');
  const hostClientId = useHostClientId();
  const [recorder, setRecorder] = useState<Recorder | null>(null);

  const handleToggle = useCallback(() => {
    if (!hostClientId) return;

    if (recorder) {
      if (!recorder) return;
      recorder.stop();
    } else {
      const [audio] = stage.getTracksByUid(hostClientId);
      if (!audio) return;

      setRecorder(
        new Recorder(
          new MediaStream([audio.getMediaStreamTrack().clone()]),
          () => {
            setRecorder(null);
          }
        )
      );
    }
  }, [hostClientId, recorder, stage]);

  useEffect(() => {
    return () => recorder?.stop();
  }, [recorder]);

  return (
    <button
      type='button'
      className={`${
        recorder ? 'btn-delete' : 'btn-primary'
      } w-full px-10 h-7 text-sms`}
      onClick={handleToggle}
    >
      {recorder ? 'Stop Recording' : 'Record Host Audio'}
    </button>
  );
}

class Recorder {
  private r: MediaRecorder;

  constructor(stream: MediaStream, onDone: () => void) {
    const chunks: Blob[] = [];
    this.r = new MediaRecorder(stream, {
      audioBitsPerSecond: 192000,
    });
    this.r.ondataavailable = (ev) => {
      if (ev.data.size > 0) chunks.push(ev.data);
    };

    this.r.onstop = () => {
      download(chunks);
      onDone();
    };

    this.r.start();
  }

  stop() {
    this.r.stop();
  }
}

function download(chunks: Blob[]) {
  const blob = new Blob(chunks, {
    type: 'video/webm',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = 'test.webm';
  a.click();
  window.URL.revokeObjectURL(url);
}
