import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import config from '../../config';
import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../../hooks/useFeatureQueryParam';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { ClientTypeUtils, hasDebugFlag } from '../../types';
import { rsDestroy, rsInit } from '../../utils/rstats.client';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { Draggable } from '../Draggable';
import { useEXPSDetractorPctPoller } from '../ExperienceScore';
import { CircleAddIcon } from '../icons/AddIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { MinimizedMenuIcon } from '../icons/MinimizedMenuIcon';
import { CircleMinusIcon, MinusIcon } from '../icons/MinusIcon';
import { PushPinIcon } from '../icons/PushPinIcon';
import { ToolsIcon } from '../icons/ToolsIcon';
import { useUser } from '../UserContext';
import { useMyClientType } from '../Venue';
import { AdHocVoiceOversPanel } from './DebugTools/AdHocVoiceOvers';
import { BGAudioButton } from './DebugTools/BGAudio';
import { FirebaseConnection } from './DebugTools/Firebase';
import { HostAudio } from './DebugTools/HostAudio';
import { HostAudioRecordButton } from './DebugTools/HostRecorder';
import {
  HostStreamStatsWrapper,
  HostStreamSwitchButton,
} from './DebugTools/HostStream';
import { LoggerScopes } from './DebugTools/LoggerScopes';
import {
  OnDGameControlIndicator,
  OnDGameControlTools,
} from './DebugTools/OnDGameControlTools';
import { ParticipantTools } from './DebugTools/ParticipantTools';
import { PlaybackDetails } from './DebugTools/PlaybackDetails';
import { CopyStreamIdButton, SentimentPreview } from './DebugTools/Sentiment';
import { SFXPreview } from './DebugTools/SFXPreview';
import { TeamsTools } from './DebugTools/TeamsTools';
import { URLFeatures } from './DebugTools/URLFeatures';
import {
  CopyVenueInfoButton,
  SelectCoordinatorPolicyOverride,
} from './DebugTools/Venue';
import { VenueEventTools } from './DebugTools/VenueEventTools';
import { VideoPlayback } from './DebugTools/VideoPlayback';

const debugToolsQueryParam = getFeatureQueryParamArray('debug-tools');
const debugToolsRstatsQueryParam = getFeatureQueryParam('debug-tools-rstats');
const debugToolsRstatsUserTimingQueryParam = getFeatureQueryParamArray(
  'debug-tools-rstats-user-timing'
);
const debugToolsInitiallyOpen =
  debugToolsQueryParam === 'float' || debugToolsQueryParam === 'collapsed';

const concoleCtrlEnabled = config.misc.consoleCtrl;

export const DebugToolsButton = memo(
  function DebugToolsButton(): JSX.Element | null {
    const [showButton, setShowButton] = useState(
      debugToolsQueryParam !== 'disabled'
    );
    const me = useUser();
    const withDebugFlag = hasDebugFlag(me);

    useEffect(() => {
      if (!withDebugFlag) return;
      setShowButton(true);
    }, [withDebugFlag]);

    const [panelOpen, togglePanelOpen] = useState(debugToolsInitiallyOpen);
    const [pin, setPin] = useState(debugToolsInitiallyOpen);

    useEffect(() => {
      if (!concoleCtrlEnabled) return;
      uncheckedIndexAccess_UNSAFE(window)['lp'] ||= {};
      uncheckedIndexAccess_UNSAFE(window)['lp']['debugTools'] = {
        enable: (pin = true) => {
          setShowButton(true);
          togglePanelOpen(true);
          setPin(pin);
        },
        disable: () => {
          setShowButton(false);
          togglePanelOpen(false);
        },
      };
      return () => {
        if (typeof uncheckedIndexAccess_UNSAFE(window)['lp'] === 'object') {
          delete uncheckedIndexAccess_UNSAFE(window)['lp']['debugTools'];
        }
      };
    }, []);

    const ref = useRef<HTMLDivElement | null>(null);
    useOutsideClick(ref, () => !pin && togglePanelOpen(false));

    const togglePin = useCallback(() => {
      setPin((v) => {
        return !v;
      });
    }, []);

    return !showButton ? null : (
      <div ref={ref} className='my-1.5'>
        <button
          type='button'
          className='icon-btn relative'
          onClick={() => togglePanelOpen(!panelOpen)}
        >
          {panelOpen ? <CircleMinusIcon /> : <CircleAddIcon />}
          <OnDGameControlIndicator />
        </button>
        {panelOpen ? <DebugTools pin={pin} togglePin={togglePin} /> : null}
      </div>
    );
  }
);

function ExperienceScoreValue() {
  const score = useEXPSDetractorPctPoller();
  return (
    <span className='text-secondary'>(Detractor Percentage: {score}%)</span>
  );
}

function DebugTools(props: { pin: boolean; togglePin: () => void }) {
  const { pin, togglePin } = props;
  const rstatsDestination = useRef<HTMLDivElement | null>(null);

  const myClientType = useMyClientType();
  const isHostClient = ClientTypeUtils.isHost(myClientType);

  const [collapsed, setCollapsed] = useState(
    debugToolsQueryParam === 'collapsed'
  );
  const [statsOn, setStatsOn] = useState(debugToolsRstatsQueryParam);

  useLayoutEffect(() => {
    if (statsOn)
      rsInit(
        {
          isHostClient,
          userTimingEnabled: debugToolsRstatsUserTimingQueryParam === 'enabled',
        },
        rstatsDestination.current
      );
    return () => rsDestroy();
  }, [isHostClient, statsOn]);

  const btnClasses = `icon-btn w-8 h-8 bg-black hover:bg-black hover:bg-opacity-100`;

  return (
    <Draggable>
      {(containerRef, handleRef) => (
        <div ref={containerRef} className='absolute bottom-0 left-14'>
          <div ref={rstatsDestination} className='relative'></div>
          <div className='w-90 border border-[#303436] rounded-xl p-1 bg-lp-black-004'>
            <header
              ref={handleRef}
              className='
                p-3 text-2xs font-bold text-white cursor-move
                flex justify-between
              '
            >
              <span>
                Debug Tools <ExperienceScoreValue />
              </span>
            </header>

            <div
              className='
                absolute -top-2 -right-2 text-2xs
                flex gap-2
              '
            >
              <button
                className={`
                  ${btnClasses}
                  ${statsOn ? 'text-white' : 'text-secondary'}
                  px-3 py-1
                `}
                type='button'
                onClick={() => setStatsOn((v) => !v)}
                title='Enable/Disable stats. debug-tools-rstats=disabled if you want always disabled'
              >
                {statsOn ? <ToolsIcon /> : <MinusIcon />}
              </button>

              <button
                className={`
                  ${btnClasses}
                  text-secondary
                  px-3 py-1
                `}
                type='button'
                onClick={() => setCollapsed((v) => !v)}
                title='Collapse/Expand. debug-tools=collapsed if you want always collapsed'
              >
                {collapsed ? <MenuIcon /> : <MinimizedMenuIcon />}
              </button>

              <button
                type='button'
                className={`
                  ${btnClasses}
                  ${pin ? 'text-white' : 'text-secondary'}
                `}
                onClick={togglePin}
                title='Pin/Unpin. debug-tools=float if you want always floating'
              >
                <PushPinIcon />
              </button>
            </div>

            <div
              className={`max-h-140 overflow-y-scroll scrollbar-always ${
                collapsed ? 'hidden' : 'block'
              }`}
            >
              <section className='mx-3'>
                <URLFeatures />
              </section>
              <section className='mx-3'>
                <LoggerScopes />
              </section>
              <section className='m-3 flex flex-col justify-between space-y-5'>
                <header className='text-2xs font-bold text-white'>Venue</header>
                <CopyVenueInfoButton />
                <SelectCoordinatorPolicyOverride />
              </section>
              <section className='m-3 flex flex-col justify-between space-y-5'>
                <header className='text-2xs font-bold text-white'>Audio</header>
                <HostAudioRecordButton />
                <BGAudioButton />
                <HostAudio />
              </section>
              <section className='m-3 flex flex-col justify-between space-y-5'>
                <header className='text-2xs font-bold text-white'>
                  VoiceOvers
                </header>
                <AdHocVoiceOversPanel />
              </section>
              <section className='m-3 flex flex-col justify-between space-y-5'>
                <header className='text-2xs font-bold text-white'>SFX</header>
                <SFXPreview />
              </section>
              <section className='m-3 flex flex-col justify-between space-y-5'>
                <header className='text-2xs font-bold text-white'>
                  Sentiment
                </header>
                <CopyStreamIdButton />
                <SentimentPreview />
              </section>
              <section className='m-3 flex flex-col justify-between space-y-5'>
                <header className='text-2xs font-bold text-white'>
                  WebRTC (Host)
                </header>
                <HostStreamSwitchButton />
                <HostStreamStatsWrapper />
              </section>
              <section className='m-3 flex flex-col justify-between'>
                <header className='text-2xs font-bold text-white mb-2'>
                  Participants
                </header>
                <ParticipantTools />
              </section>
              <section className='m-3 flex flex-col justify-between space-y-5'>
                <header className='text-2xs font-bold text-white'>
                  Video Playback
                </header>
                <VideoPlayback />
              </section>
              <section className='m-3 flex flex-col justify-between space-y-5'>
                <header className='text-2xs font-bold text-white'>
                  Firebase
                </header>
                <FirebaseConnection />
              </section>
              <section className='m-3 flex flex-col justify-between space-y-5'>
                <header className='text-2xs font-bold text-white'>Teams</header>
                <TeamsTools />
              </section>
              <section className='m-3 flex flex-col justify-between space-y-5'>
                <header className='text-2xs font-bold text-white'>
                  OnD Game Control
                </header>
                <OnDGameControlTools />
              </section>
              <section className='m-3 flex flex-col gap-5'>
                <header className='text-2xs font-bold text-white'>
                  Playback
                </header>
                <PlaybackDetails />
              </section>
              <section className='m-3 flex flex-col gap-5'>
                <header className='text-2xs font-bold text-white'>
                  Venue Event Details
                </header>
                <VenueEventTools />
              </section>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
