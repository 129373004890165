import { useEffect, useState } from 'react';

import { asFBReference } from '@lp-lib/firebase-typesafe';

import { getLogger, safeWindowReload } from '../../logger/logger';
import { createProvider } from '../../utils/createProvider';
import { type FirebaseService, firebaseService } from '../Firebase';
import {
  type FBMsgPassStorage,
  recv,
  send,
} from '../Firebase/FirebaseMessagePassing';
import { useMyInstanceGetter } from '../Player';

type RemoteRefreshCmd = {
  kind: 'restart';
  targetClientId: string;
};

type RemoteRefreshAPIDependencies = {
  getMyInstance: ReturnType<typeof useMyInstanceGetter>;
};

class RemoteRefreshAPI {
  aborter = new AbortController();
  deps: null | RemoteRefreshAPIDependencies = null;

  constructor(
    svc: FirebaseService,
    private log = getLogger().scoped('RemoteRefreshAPI'),
    private ref = asFBReference<FBMsgPassStorage>(
      svc.prefixedSafeRef('remote-refresh')
    )
  ) {}

  syncDeps(deps: RemoteRefreshAPIDependencies) {
    this.deps = deps;
  }

  async open() {
    this.aborter.abort();
    this.aborter = new AbortController();

    recv(
      this.ref,
      async (msg: RemoteRefreshCmd, _id) => {
        if (msg.kind === 'restart') {
          if (msg.targetClientId === this.deps?.getMyInstance()?.clientId) {
            this.log.info('recv restart command, reloading window');
            await safeWindowReload();
          }
        }
      },
      { signal: this.aborter.signal }
    );
  }

  close() {
    this.aborter.abort();
  }

  sendRefreshCmd = async (targetClientId: string) => {
    const msg: RemoteRefreshCmd = { kind: 'restart', targetClientId };
    await send(this.ref, msg);
  };
}

const { Provider, useCreatedContext } =
  createProvider<RemoteRefreshAPI>('RemoteRestart');

export function RemoteRefreshProvider(props: { children?: React.ReactNode }) {
  const [instance] = useState(() => new RemoteRefreshAPI(firebaseService));
  const getMyInstance = useMyInstanceGetter();

  useEffect(() => {
    instance.open();
    return () => {
      instance.close();
    };
  }, [instance]);

  useEffect(() => {
    instance.syncDeps({ getMyInstance });
  }, [getMyInstance, instance]);

  return <Provider value={instance}>{props.children}</Provider>;
}

export function useRemoteRefresh() {
  const ctx = useCreatedContext();
  return ctx.sendRefreshCmd;
}
