import logger from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { backoffSleep } from '../../utils/backoffSleep';
import { type UploadSentimentPayload } from './type';

const log = logger.scoped('sentiment');
const maxAttempts = 10;
const maxBackOffMs = 30000;

export class Uploader {
  private queue: UploadSentimentPayload[];
  private stopped: boolean;
  constructor() {
    this.queue = [];
    this.stopped = false;
  }
  enqueue(payload: UploadSentimentPayload): void {
    this.queue.push(payload);
  }
  private async consume(): Promise<void> {
    let attempts = 0;
    while (this.queue.length > 0 && attempts <= maxAttempts) {
      await backoffSleep(attempts, maxBackOffMs);
      const payload = this.queue.shift();
      if (payload) {
        try {
          await apiService.session.uploadSessionSentiment(
            payload.venueId,
            payload.sessionId,
            payload.record
          );
        } catch (error) {
          attempts++;
          log.error('upload error', error);
          this.queue.unshift(payload);
        }
      }
    }
  }
  async stop(drain = true): Promise<void> {
    this.stopped = true;
    if (drain) {
      await this.consume();
      // reset queue even if consume failed
      this.queue = [];
    }
  }
  start(interval = 5000): void {
    this.stopped = false;
    const run = async () => {
      await this.consume();
      if (!this.stopped) {
        setTimeout(run, interval);
      }
    };
    setTimeout(run, interval);
  }
}
