import TFJSBackendWASM from '@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm.wasm?url';
import TFJSBackendWASMSIMD from '@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm-simd.wasm?url';
import TFJSBackendWASMThreadedSIMD from '@tensorflow/tfjs-backend-wasm/dist/tfjs-backend-wasm-threaded-simd.wasm?url';
import { version as externalWasmVersion } from '@tensorflow/tfjs-backend-wasm/dist/version';
import * as faceapi from '@vladmandic/face-api';
import FaceAPIFaceExpressionModel from '@vladmandic/face-api/model/face_expression_model.bin?url';
import FaceAPIFaceExpressionModelWeightsManifest from '@vladmandic/face-api/model/face_expression_model-weights_manifest.json?url';
import FaceAPITinyFaceDetectorModel from '@vladmandic/face-api/model/tiny_face_detector_model.bin?url';
import FaceAPITinyFaceDetectorModelWeightsManifest from '@vladmandic/face-api/model/tiny_face_detector_model-weights_manifest.json?url';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';

declare module '@vladmandic/face-api' {
  // This module manually re-exports very specific tfjs exports in its types!
  // But they are actually there in the tfjs source. Manually define them by
  // augmenting the module manually.

  type WasmBinaryName =
    | 'tfjs-backend-wasm.wasm'
    | 'tfjs-backend-wasm-simd.wasm'
    | 'tfjs-backend-wasm-threaded-simd.wasm';

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace tf {
    // https://github.com/tensorflow/tfjs/blob/818d672e1b5f3153beb1c3bc656c077c7d1f58d8/tfjs-backend-wasm/src/backend_wasm.ts#L479
    export function setWasmPaths(
      prefixOrFileMap: string | { [key in WasmBinaryName]?: string },
      usePlatformFetch?: boolean
    ): Promise<void>;
    export function setBackend(backend: 'wasm' | 'webgl'): Promise<void>;
    export function ready(): Promise<void>;
  }
}

async function assertVersionMatch() {
  // We only import the `version` using an internal path
  // ('@tensorflow/tfjs-backend-wasm/dist/version') in order to avoid the
  // automatic initialization that backend-wasm does (lots of warnings, and extra
  // work). face-api already bundles a version of tfjs-backend-wasm, but does not
  // include the actual wasm. So we use the wasm as shipped in the standalone
  // bundle. They need to match the version of TFJS they were compiled for. Assert
  // this is true! When upgrading face-api, be sure to upgrade tfjs-backend-wasm
  // as well to the matching bundled version.

  const bundledWasmVersion =
    'tfjs-backend-wasm' in faceapi.tf.version
      ? uncheckedIndexAccess_UNSAFE(faceapi.tf.version)['tfjs-backend-wasm']
      : null;

  const msg = [
    `TFJS_WASM_VERSION_MISMATCH: `,
    `externalWasmVersion (${externalWasmVersion})`,
    ` must match `,
    `bundledWasmVersion (${bundledWasmVersion})`,
  ].join('');

  if (
    !bundledWasmVersion ||
    !externalWasmVersion ||
    externalWasmVersion !== bundledWasmVersion
  ) {
    throw new Error(msg);
  }
}

async function weightMapFrom(manifestUrl: string, weightsUrl: string) {
  const res = await Promise.all([fetch(manifestUrl), fetch(weightsUrl)]);
  const results = await Promise.all([res[0].json(), res[1].arrayBuffer()]);

  const manifest: faceapi.tf.io.WeightsManifestConfig = results[0];
  const weights = results[1];

  // NOTE(drew): Both of the manifest/weight pairs have only 1 entry. This means
  // this function is not a general loader. It wasn't immediately clear to me
  // how to implement code similar to
  // https://github.com/tensorflow/tfjs/blob/f0f981fe306bf548e300536aca485c0ffdd6619e/tfjs-core/src/io/weights_loader.ts#L158C17-L158C37
  // under the time constraints I had.
  if (manifest.length !== 1)
    throw new Error(
      'FaceAPIFaceExpressionModelWeightsManifest: Unexpected number of weight entries'
    );

  return faceapi.tf.io.decodeWeights(weights, manifest[0].weights);
}

export async function initialize(
  sentimentBackend: 'wasm' | 'webgl',
  worker = false
): Promise<void> {
  await assertVersionMatch();
  const simdSupported = await faceapi.tf.ENV.getAsync('WASM_HAS_SIMD_SUPPORT');
  // Manually specifying the modules allows the Vite Asset Pipeline
  // to immutably cache URLs via content hashing.
  await faceapi.tf.setWasmPaths({
    'tfjs-backend-wasm.wasm': TFJSBackendWASM,
    'tfjs-backend-wasm-simd.wasm': TFJSBackendWASMSIMD,
    'tfjs-backend-wasm-threaded-simd.wasm': TFJSBackendWASMThreadedSIMD,
  });
  if (worker) {
    await faceapi.tf.setBackend(sentimentBackend);
  } else {
    await faceapi.tf.setBackend(
      sentimentBackend === 'wasm' && simdSupported ? 'wasm' : 'webgl'
    );
  }
  await faceapi.tf.ready();

  const weightMaps = await Promise.all([
    // Again, manually specifying the modules allows the Vite Asset Pipeline
    // to immutably cache URLs via content hashing.

    weightMapFrom(
      FaceAPIFaceExpressionModelWeightsManifest,
      FaceAPIFaceExpressionModel
    ),
    weightMapFrom(
      FaceAPITinyFaceDetectorModelWeightsManifest,
      FaceAPITinyFaceDetectorModel
    ),
  ]);

  faceapi.nets.faceExpressionNet.loadFromWeightMap(weightMaps[0]);
  faceapi.nets.tinyFaceDetector.loadFromWeightMap(weightMaps[1]);
}

export function isVideoReady(video: HTMLVideoElement): boolean {
  return !(
    video.readyState < 2 ||
    video.videoWidth === 0 ||
    video.videoHeight === 0
  );
}
