import { type ReactNode, useEffect, useState } from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { getLogger } from '../../../logger/logger';
import { BrowserIntervalCtrl } from '../../../utils/BrowserIntervalCtrl';

export function LoggerScopes(): JSX.Element {
  const [knownScopes, setKnownScopes] = useState(() =>
    getLogger().knownScopes()
  );

  const [enabledScopes, setEnabledScopes] = useState(() =>
    getLogger().enabledScopes()
  );

  const refreshKnownScopes = useLiveCallback(() => {
    setKnownScopes(getLogger().knownScopes());
  });

  const refreshEnabledScopes = useLiveCallback(() => {
    setEnabledScopes(getLogger().enabledScopes());
  });

  useEffect(() => {
    const ctrl = new BrowserIntervalCtrl();
    ctrl.set(() => {
      refreshKnownScopes();
    }, 1000);

    return () => ctrl.clear();
  }, [refreshKnownScopes]);

  const singles: [string, ReactNode][] = [];

  for (const scope of knownScopes) {
    const item = (
      <label
        key={scope}
        className='text-3xs font-light text-gray-200 hover:bg-lp-gray-006 flex justify-between items-center'
      >
        {scope}
        <input
          type='checkbox'
          checked={enabledScopes.has(scope)}
          onChange={() => {
            const logger = getLogger();
            if (logger.enabledScopes().has(scope)) {
              logger.disableScope(scope);
            } else {
              logger.enableScope(scope);
            }

            refreshEnabledScopes();
          }}
        />
      </label>
    );

    singles.push([scope, item]);
  }

  singles.sort((a, b) => a[0].localeCompare(b[0]));

  return (
    <div className='flex flex-col gap-2'>
      <details>
        <summary className='text-2xs font-bold my-3 text-white '>
          <header className='inline'>Known Logger Scopes</header>
        </summary>
        <div className='flex flex-col gap-1'>
          {singles.map(([_, elem]) => elem)}
        </div>
      </details>
    </div>
  );
}
