import { useState } from 'react';

import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { useBotAPI } from '../Bot';
import { useClock } from '../Clock';
import { useParticipantsFlagsGetter } from '../Player';
import { useTownhallAPI } from './Provider';

export function useMockSpeakingBots(intervalMs = 2000) {
  const botAPI = useBotAPI();
  const townhallAPI = useTownhallAPI();
  const clock = useClock();
  const ctrl = useInstance(() => new BrowserIntervalCtrl());
  const [running, setRunning] = useState(false);
  const stop = useLiveCallback(() => {
    ctrl.clear();
    setRunning(false);
  });
  const getParticipantsFlag = useParticipantsFlagsGetter();
  const start = useLiveCallback(() => {
    stop();
    ctrl.set(async () => {
      const bots = await botAPI.getBots();
      if (bots.length === 0) return;
      const promises = [];
      const participantFlags = getParticipantsFlag();
      for (const bot of bots) {
        const flags = participantFlags[bot.clientId];
        if (!flags?.audio) {
          promises.push(botAPI.update(bot.clientId, { audio: true }));
        }
        promises.push(townhallAPI.setLastSpokenAt(bot.clientId, clock.now()));
      }
      await Promise.all(promises);
    }, intervalMs);
    setRunning(true);
  });

  return { start, stop, running };
}
