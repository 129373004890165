import * as faceapi from '@vladmandic/face-api';

import config from '../../config';
import {
  getFeatureQueryParamArray,
  getFeatureQueryParamNumber,
} from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { Canvas2DHelper, HiddenCanvas, ScalePolicy } from '../../utils/canvas';
import { Emitter } from '../../utils/emitter';
import { type WorkerDetectorAPI } from './detector.worker';
import { initialize, isVideoReady } from './helper';
import { type SortedExpressions } from './type';

const log = logger.scoped('sentiment');
const sentimentBackend = getFeatureQueryParamArray('sentiment-backend');
const sentimentConfidenceThreshold = getFeatureQueryParamNumber(
  'sentiment-confidence-threshold',
  true
);

type SentimentDetectorEvents = {
  'expressions-detected': (expressions: SortedExpressions) => void;
};

export interface IDetectorExecutor {
  getType(): string;
  detectFaceExpressions(): Promise<SortedExpressions | undefined>;
  detectFace(): Promise<faceapi.FaceDetection | undefined>;
  close(): void;
}

export class EmbeddedExecutor implements IDetectorExecutor {
  // Use the same promise for all callers to prevent multiple `init()` calls
  // from initializing more than once.
  private inited: null | Promise<void>;
  constructor(private video: HTMLVideoElement) {
    this.video = video;
    this.inited = null;
  }
  private async init(): Promise<void> {
    if (!this.inited) this.inited = initialize(sentimentBackend);
    await this.inited;
  }

  getType(): string {
    return 'embedded';
  }

  async detectFaceExpressions(): Promise<SortedExpressions | undefined> {
    await this.init();
    const detections = await faceapi
      .detectSingleFace(
        this.video,
        new faceapi.TinyFaceDetectorOptions({ inputSize: 160 })
      )
      .withFaceExpressions();
    return detections?.expressions.asSortedArray();
  }

  async detectFace(): Promise<faceapi.FaceDetection | undefined> {
    await this.init();
    return faceapi.detectSingleFace(
      this.video,
      new faceapi.TinyFaceDetectorOptions({ inputSize: 160 })
    );
  }

  close(): void {
    void 0;
  }
}

export class WorkerExecutor implements IDetectorExecutor {
  constructor(
    private video: HTMLVideoElement,
    width = 320,
    height = 240,
    private ocvs = new HiddenCanvas('sentiment-worker-proxy'),
    private cvs = ocvs.cvs,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    private ctx = cvs.getContext('2d', {
      alpha: false,
      willReadFrequently: true,
    })!,
    private ctxHelper = new Canvas2DHelper(ctx),
    private worker = new ComlinkWorker<WorkerDetectorAPI>(
      new URL('./detector.worker.ts', import.meta.url)
    )
  ) {
    this.video = video;
    this.cvs.width = width;
    this.cvs.height = height;
  }

  getType(): string {
    return 'worker';
  }

  private getImageData(): ImageData {
    this.ctxHelper.drawImage(
      this.video,
      this.cvs.width,
      this.cvs.height,
      ScalePolicy.ScaleSource
    );
    return this.ctx.getImageData(0, 0, this.cvs.width, this.cvs.height);
  }

  async detectFaceExpressions(): Promise<SortedExpressions | undefined> {
    if (!isVideoReady(this.video)) return;
    return await this.worker.detectFaceExpressions(this.getImageData());
  }

  async detectFace(): Promise<faceapi.FaceDetection | undefined> {
    if (!isVideoReady(this.video)) return;
    return await this.worker.detectFace(this.getImageData());
  }

  close(): void {
    this.ocvs.detach();
    this.worker.terminate();
  }
}

export interface IDetector {
  start: (interval: number) => Promise<void>;
  stop(reason: string): void;
  pause(val: boolean): void;
  detectFace(): Promise<faceapi.FaceDetection | undefined>;
}

export class Detector implements IDetector {
  readonly executor: IDetectorExecutor;
  private timerId: ReturnType<typeof setInterval> | null;
  private paused: boolean;
  private emitter = new Emitter<SentimentDetectorEvents>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);
  constructor(executor: IDetectorExecutor) {
    this.executor = executor;
    this.timerId = null;
    this.paused = false;
  }

  async start(interval = 1000): Promise<void> {
    log.info(`detector started with ${this.executor.getType()} executor`);
    this.stop();
    this.timerId = setInterval(async () => {
      if (this.paused) return;
      const expressions = await this.executor.detectFaceExpressions();
      if (expressions) {
        const filtered = expressions.filter(
          (e) =>
            e.probability >= sentimentConfidenceThreshold &&
            config.sentiment.expressions.includes(e.expression)
        );
        if (filtered.length > 0) {
          this.emitter.emit('expressions-detected', filtered);
        }
      }
    }, interval);
  }

  stop(reason = 'unload'): void {
    if (this.timerId) {
      clearInterval(this.timerId);
      log.info('detector stopped', { reason });
      this.timerId = null;
    }
  }

  pause(val: boolean): void {
    this.paused = val;
    log.info(`detector ${!val ? 'resumed' : 'paused'}`);
  }

  async detectFace(): Promise<faceapi.FaceDetection | undefined> {
    const detections = await this.executor.detectFace();
    return detections;
  }
}
