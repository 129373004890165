import { AIDenoiserProcessorLevel } from 'agora-extension-ai-denoiser';
import { useEffect, useState } from 'react';

import { useForceUpdate } from '../../../hooks/useForceUpdate';
import { useInstance } from '../../../hooks/useInstance';
import { TrackState } from '../../../services/webrtc';
import { useBotAPI } from '../../Bot';
import { SwitcherControlled } from '../../Switcher';
import { useModeAwareAudienceRTCService } from '../../Townhall';
import { useMockSpeakingBots } from '../../Townhall/TownhallBotsUtilities';

function DenoiserTools(): JSX.Element {
  const audienceRTCService = useModeAwareAudienceRTCService();
  const denosier = audienceRTCService?.denosier();
  const [open, setOpen] = useState(false);
  const [level, setLevel] = useState(AIDenoiserProcessorLevel.SOFT);
  const forceUpdate = useForceUpdate();

  const toggleDenosier = async () => {
    if (!denosier) return;
    if (denosier.enabled) {
      await denosier.disable();
    } else {
      await denosier.enable();
    }
    forceUpdate();
  };

  const changeLevel = (level: string) => {
    if (!denosier) return;
    denosier.setLevel(level as AIDenoiserProcessorLevel);
    setLevel(level as AIDenoiserProcessorLevel);
  };

  return (
    <div className='mb-4'>
      <button
        type='button'
        className='btn-primary w-full px-10 h-7 text-sms flex items-center justify-center'
        disabled={!denosier}
        onClick={() => setOpen(!open)}
      >
        {!denosier ? 'Denosier not available' : 'Denosier Configuration'}
      </button>
      {open && (
        <>
          <div className='flex justify-between mt-4'>
            <div>
              <div className='flex items-center text-white text-2xs'>
                Toggle Denosier
              </div>
              <div className='mt-1 text-3xs text-icon-gray'>
                Enable/disable Audio Denosier
              </div>
            </div>

            <div className='flex-shrink-0'>
              <SwitcherControlled
                name='toggle-team-audio-denosier'
                checked={!!denosier?.enabled}
                onChange={toggleDenosier}
              />
            </div>
          </div>
          <div className='flex justify-between mt-4'>
            <div>
              <div className='flex items-center text-white text-2xs'>
                Denosier Level
              </div>
              <div className='mt-1 text-3xs text-icon-gray'>
                change the denoiser level
              </div>
            </div>

            <div className='flex-shrink-0 text-2xs'>
              <select
                className='bg-transparent text-gray-50 text-right'
                value={level}
                onChange={(e) => changeLevel(e.target.value)}
              >
                <option
                  className='bg-lp-black-001 text-gray-50'
                  value={AIDenoiserProcessorLevel.SOFT}
                >
                  Soft
                </option>
                <option
                  className='bg-lp-black-001 text-gray-50'
                  value={AIDenoiserProcessorLevel.AGGRESSIVE}
                >
                  Aggressive
                </option>
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function BotsGenerator(): JSX.Element {
  const [num, setNum] = useState(2);
  const options = useInstance(() => [1, 2, 4, 5, 10, 20, 50, 100]);
  const generator = useBotAPI();
  const mockSpeaking = useMockSpeakingBots();

  const handleGenerate = async () => {
    await generator.generate('default', num, 4, true);
  };

  const handleGenerateForDemo = async () => {
    await generator.generate('demo', num, 8, true);
  };

  const handleClear = async () => {
    await generator.clear();
  };

  return (
    <div className='flex flex-col mb-4'>
      <div className='flex justify-between'>
        <div>
          <div className='flex items-center text-white text-2xs'>Bots</div>
          <div className='mt-1 text-3xs text-icon-gray'>
            Generate bots without Agora Streams
          </div>
        </div>

        <div className='flex-shrink-0 text-2xs'>
          <select
            className='bg-transparent text-gray-50'
            value={num}
            onChange={(e) => setNum(Number(e.target.value))}
          >
            {options.map((option) => (
              <option
                key={option}
                className='bg-lp-black-001 text-gray-50'
                value={option}
              >
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='w-full flex justify-between gap-2 h-7 text-sms mt-2'>
        <button
          type='button'
          className='btn-primary w-1/3'
          onClick={handleGenerate}
        >
          Generate
        </button>
        <button
          type='button'
          className='btn-warning text-black w-1/3'
          onClick={handleGenerateForDemo}
        >
          For Demo
        </button>
        <button
          type='button'
          className='btn-delete w-1/3'
          onClick={handleClear}
        >
          Clear
        </button>
      </div>
      <div className='w-full flex justify-between gap-2 h-7 text-sms mt-2'>
        {!mockSpeaking.running ? (
          <button
            type='button'
            className='btn-primary w-full'
            onClick={() => mockSpeaking.start()}
          >
            Mock Speaking
          </button>
        ) : (
          <button
            type='button'
            className='btn-delete w-full'
            onClick={() => mockSpeaking.stop()}
          >
            Stop Speaking
          </button>
        )}
      </div>
    </div>
  );
}

export function TeamsTools(): JSX.Element {
  const rtc = useModeAwareAudienceRTCService();
  const [autoRecoveryEnabled, setAutoRecoveryEnabled] = useState(true);
  const [faultInjectionEnabled, setFaultInjectionEnabled] = useState(false);

  const toggleAutoRecovery = () => {
    if (autoRecoveryEnabled) {
      setAutoRecoveryEnabled(false);
      rtc?.videoRecovery?.disable();
    } else {
      setAutoRecoveryEnabled(true);
      rtc?.videoRecovery?.enable();
    }
  };

  useEffect(() => {
    if (!faultInjectionEnabled || !rtc) return;
    const timerId = setInterval(() => {
      const map = rtc.getVideoTrackStateMap();
      let injected = false;
      for (const [uid, state] of Object.entries(map)) {
        if (state === TrackState.Live) {
          rtc.updateVideoTrackState(uid, TrackState.Disconnected);
          injected = true;
        }
      }
      if (injected) {
        setFaultInjectionEnabled(false);
        clearInterval(timerId);
      }
    }, 2000);
    return () => {
      clearInterval(timerId);
    };
  }, [faultInjectionEnabled, rtc]);

  return (
    <div className='my-2'>
      <div className='flex justify-between mb-4'>
        <div>
          <div className='flex items-center text-white text-2xs'>
            Auto Recovery
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            Enable/disable video stream auto recovery
          </div>
        </div>
        <div className='flex-shrink-0'>
          <SwitcherControlled
            name='toggle-team-stream-auto-recovery'
            checked={autoRecoveryEnabled}
            onChange={toggleAutoRecovery}
          />
        </div>
      </div>
      <div className='flex justify-between mb-4'>
        <div>
          <div className='flex items-center text-white text-2xs'>
            Toggle Team Streams Fault Injection
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            Simulate the Agora video disconnection
          </div>
        </div>
        <div className='flex-shrink-0'>
          <SwitcherControlled
            name='toggle-team-stream-fault-injection'
            checked={faultInjectionEnabled}
            onChange={() => setFaultInjectionEnabled(!faultInjectionEnabled)}
          />
        </div>
      </div>
      <BotsGenerator />
      <DenoiserTools />
    </div>
  );
}
