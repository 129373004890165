import { useMemo } from 'react';

const tdClass = `border-t border-secondary pt-1`;
const tdMono = `pl-1 text-3xs text-icon-gray whitespace-pre-wrap font-mono`;

function JsonTableKeyCell(props: { children: React.ReactNode }) {
  return <td className={`${tdClass}`}>{props.children}</td>;
}

function JsonTableValueCell(props: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <td className={`${tdClass} ${props.className ?? tdMono}`}>
      {props.children}
    </td>
  );
}

export function JsonTable<T extends object>(props: {
  obj: T | null | undefined;
}) {
  const { obj } = props;

  const entries = useMemo(() => obj && Object.entries(obj), [obj]);

  return useMemo(() => {
    const primitives = entries?.filter(
      ([, v]) =>
        typeof v === 'string' || typeof v === 'number' || typeof v === 'boolean'
    );

    const objects = entries?.filter(([, v]) => typeof v === 'object');

    const header = primitives?.map(([k, v]) => (
      <tr key={k}>
        <JsonTableKeyCell>{k}</JsonTableKeyCell>
        <JsonTableValueCell>{String(v)}</JsonTableValueCell>
      </tr>
    ));

    const rows = objects?.map(([k, v]) => (
      <tr key={k}>
        <JsonTableKeyCell>{k}</JsonTableKeyCell>
        <JsonTableValueCell>
          <details>
            <summary>Expand</summary>
            {JSON.stringify(v, null, ' ')}
          </details>
        </JsonTableValueCell>
      </tr>
    ));

    return (
      <table
        className='table-fixed text-white text-2xs '
        style={{ borderCollapse: 'separate', borderSpacing: '0 0.25rem' }}
      >
        <thead>
          <tr>
            <th className='w-1/3 text-icon-gray'>Property</th>
            <th className='text-icon-gray'>Value</th>
          </tr>
        </thead>
        <tbody className='align-baseline'>
          {header}
          {rows}
        </tbody>
      </table>
    );
  }, [entries]);
}
