import { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';

import { useIsMounted } from '../../hooks/useIsMounted';
import { useTaskQueueRunUntilEmptyUnsafe } from '../../hooks/useTaskQueue';
import {
  mapLinearVolumeToLogarithmic,
  mapLogarithmicVolumeToLinear,
} from '../../services/audio/utils';
import { SwitcherControlled } from '../Switcher';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { useRTCService } from '../WebRTC';
import { AudioLevelMeter } from './AudioSignalMeter';

export const HostAudioMixerPanel = (): JSX.Element => {
  const min = 0;
  const max = 4;

  const hostClientId = useMyClientId();
  const stage = useRTCService('stage');
  const [micLinearGain, setMicLinearGain] = useState(
    mapLogarithmicVolumeToLinear(
      stage.audioBus.readParamValue('micPreGain'),
      0,
      max
    )
  );

  const [audioAGCEnabled, setAGCEnabled] = useState(
    stage.getMicDeviceSettings().autoGainControl
  );

  const [audioANSEnabled, setANSEnabled] = useState(
    stage.getMicDeviceSettings().noiseSuppression
  );

  useEffect(() => {
    const offMicDeviceSetting = stage.on(
      'audio-mic-device-setting-toggled',
      () => {
        const { autoGainControl, noiseSuppression } =
          stage.getMicDeviceSettings();
        setAGCEnabled(autoGainControl);
        setANSEnabled(noiseSuppression);
      }
    );

    const offMicTrackChanged = stage.audioBus.on('mic-track-changed', () => {
      const gain = mapLogarithmicVolumeToLinear(
        stage.audioBus.readParamValue('micPreGain'),
        0,
        max
      );
      setMicLinearGain(gain);
    });

    const offParam = stage.audioBus.on(
      'param-value-changed',
      (pname, pvalue) => {
        if (pname === 'micPreGain')
          setMicLinearGain(mapLogarithmicVolumeToLinear(pvalue, 0, max));
      }
    );

    return () => {
      offMicDeviceSetting();
      offParam();
      offMicTrackChanged();
    };
  }, [stage]);

  const isMounted = useIsMounted();
  const [isMonitoring, setIsMonitoring] = useState(false);
  const { addTask } = useTaskQueueRunUntilEmptyUnsafe();

  useEffect(() => {
    // Always stop monitoring once the panel is closed.
    return () => addTask(() => stage.stopAudio(hostClientId));
  }, [addTask, hostClientId, stage]);

  return (
    <div
      className='
        p-2 m-2
      bg-lp-black-004 border border-secondary rounded-xl
      text-white text-2xs
        flex flex-col gap-2
      '
    >
      <div className='flex flex-col'>
        <div className='flex flex-col gap-1 border border-secondary rounded-xl p-2'>
          <div className='flex justify-between'>
            <div>
              <div className='flex items-center text-white text-2xs'>
                Host Autogain
              </div>
              <div className='mt-1 text-3xs text-icon-gray'>
                Enable/disable microphone automatic volume normalization.
              </div>
            </div>
            <div className='flex-shrink-0'>
              <SwitcherControlled
                name='host-autogain'
                checked={audioAGCEnabled}
                onChange={(checked) => {
                  stage.toggleMicDeviceSetting('autoGainControl', checked);
                  stage.audioBus.setParamValue('micPreGain', 1);
                }}
              />
            </div>
          </div>
          <div className='flex justify-between'>
            <div>
              <div className='flex items-center text-white text-2xs'>
                Host Noise Suppression
              </div>
              <div className='mt-1 text-3xs text-icon-gray'>
                Enable/disable microphone automatic noise suppression.
              </div>
            </div>
            <div className='flex-shrink-0'>
              <SwitcherControlled
                name='host-noisesuppression'
                checked={audioANSEnabled}
                onChange={(checked) => {
                  stage.toggleMicDeviceSetting('noiseSuppression', checked);
                  stage.audioBus.setParamValue('micPreGain', 1);
                }}
              />
            </div>
          </div>
          <div className='flex justify-between'>
            <div>
              <div className='flex items-center text-white text-2xs'>
                Monitor Host Audio
              </div>
              <div className='mt-1 text-3xs text-icon-gray'>
                Enable/disable hearing (monitoring) the host's microphone
                locally.
              </div>
            </div>
            <div className='flex-shrink-0'>
              <SwitcherControlled
                name='host-localmonitor'
                checked={isMonitoring}
                onChange={(checked) => {
                  addTask(() => {
                    if (checked) stage.playAudio(hostClientId);
                    else stage.stopAudio(hostClientId);
                    // Must check `mounted` state because we're using the react-unsafe task queue
                    if (isMounted()) setIsMonitoring(checked);
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='flex gap-2 min-h-70'>
        <div className='flex flex-col gap-2'>
          <header className='self-center'>Input</header>
          <div className='flex-grow flex gap-1 border border-secondary rounded-xl p-2'>
            <div className='flex flex-col min-w-14 items-center'>
              <div className='flex-grow-0 text-center min-h-7'>Mic Gain</div>
              <ReactSlider
                className='w-8 flex-grow flex flex-col items-center select-none'
                trackClassName='volume-balancer-slider-track'
                thumbClassName='volume-balancer-slider-thumb text-4xs text-white flex justify-center items-center'
                renderThumb={(props, state) => (
                  <div {...props}>
                    {mapLinearVolumeToLogarithmic(
                      max - state.valueNow,
                      0,
                      max
                    ).toFixed(2)}
                  </div>
                )}
                min={min}
                max={max}
                value={max - micLinearGain}
                orientation='vertical'
                step={0.01}
                onChange={(val) => {
                  const inverted = max - val;
                  const logish = mapLinearVolumeToLogarithmic(inverted, 0, max);
                  stage.audioBus.setParamValue('micPreGain', logish);
                }}
              />
              <button
                className='btn-secondary py-1 px-2'
                onClick={() => {
                  stage.audioBus.setParamValue('micPreGain', 1);
                }}
              >
                Reset
              </button>
            </div>
            <div className='flex flex-col items-center min-w-14 text-2xs text-white'>
              <div className='text-center min-h-7'>Mic Signal</div>
              <AudioLevelMeter
                className='flex-grow self-stretch'
                reader={() => stage.audioBus.readParamValue('micLevelDb')}
              />
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-2'>
          <header className='self-center'>Output</header>
          <div className='flex-grow flex gap-1 border border-secondary rounded-xl p-2'>
            <div className='flex flex-col items-center min-w-14'>
              <div className='text-center min-h-7'>Host Signal</div>
              <AudioLevelMeter
                className='flex-grow self-stretch'
                reader={() => stage.audioBus.readParamValue('busLevelDb')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
