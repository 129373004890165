import { assertExhaustive } from '../../utils/common';

export type BaseProfile = {
  width: number;
  height: number;
  frameRate: number;
  maxStrips: number;
};
export type Profile = BaseProfile & { delayMs: number };

export enum ProfileIndex {
  wh320x240fps8,
  wh480x360fps8,
  wh640x480fps8,
  wh320x240fps1strip1,
}

export function profileFor(num: ProfileIndex): Profile {
  switch (num) {
    case ProfileIndex.wh320x240fps8:
      return {
        width: 320,
        height: 240,
        frameRate: 8,
        delayMs: 1000 / 8,
        maxStrips: 8 * 3,
      };
    case ProfileIndex.wh320x240fps1strip1:
      return {
        width: 320,
        height: 240,
        frameRate: 1,
        delayMs: 100,
        maxStrips: 1,
      };
    case ProfileIndex.wh480x360fps8:
      return {
        width: 480,
        height: 360,
        frameRate: 8,
        delayMs: 1000 / 8,
        maxStrips: 8 * 3,
      };
    case ProfileIndex.wh640x480fps8:
      return {
        width: 640,
        height: 480,
        frameRate: 8,
        delayMs: 1000 / 8,
        maxStrips: 8 * 3,
      };
    default:
      assertExhaustive(num);
      return {
        width: 320,
        height: 240,
        frameRate: 1,
        delayMs: 100,
        maxStrips: 1,
      };
  }
}

export type Preview = {
  expression: string;
  score: number;
  data: string;
  profileIndex: ProfileIndex;
};

export type Expression = {
  expression: string;
  probability: number;
};

export type SortedExpressions = Expression[];

export type Metadata = BaseProfile & {
  expression: string;
  score: number;
};

export type SentimentRecord = {
  clientId: string;
  userId: string;
  metadata: Metadata;
  data: string;
};

export type UploadSentimentPayload = {
  venueId: string;
  sessionId: string;
  record: SentimentRecord;
};
