import copy from 'copy-to-clipboard';

import {
  type DtoOverrideCoordinatorPolicyRequest,
  EnumsCoordinatorPolicy,
} from '@lp-lib/api-service-client/public';

import config from '../../../config';
import { apiService } from '../../../services/api-service';
import { CopyIcon } from '../../icons/CopyIcon';
import { useVenue, useVenueId } from '../../Venue/VenueProvider';

export function CopyVenueInfoButton(): JSX.Element {
  const venueId = useVenueId();

  const handleCopyVenueId = () => {
    copy(venueId);
  };

  const handleMobileVenueLink = () => {
    const params = new URLSearchParams({ 'game-play-media': 'disabled' });
    copy(`${config.app.baseUrl}/m/venue/${venueId}?${params.toString()}`);
  };

  return (
    <div className='flex items-center gap-2'>
      <button
        type='button'
        className='btn-primary w-1/2 h-7 text-sms flex items-center justify-center'
        onClick={handleCopyVenueId}
      >
        <CopyIcon className='w-4 h-4' />
        <div className='ml-1'>Copy Venue ID</div>
      </button>
      <button
        type='button'
        className='btn-primary w-1/2 h-7 text-sms flex items-center justify-center'
        onClick={handleMobileVenueLink}
      >
        <CopyIcon className='w-4 h-4' />
        <div className='ml-1'>Copy M-Venue Link</div>
      </button>
    </div>
  );
}

export function SelectCoordinatorPolicyOverride() {
  const [venue, updateVenue] = useVenue();
  const handleChange = async (req: DtoOverrideCoordinatorPolicyRequest) => {
    await apiService.venue.overrideCoordinatorPolicy(venue.id, req);
    updateVenue({
      coordinatorPolicyOverride: req.coordinatorPolicyOverride,
    });
  };

  return (
    <div className='flex justify-between'>
      <div>
        <div className='flex items-center text-white text-2xs'>
          Coordinator Policy Override
        </div>
        <div className='mt-1 text-3xs text-icon-gray'>
          Set the coordinator policy override for the venue.
        </div>
      </div>

      <div className='flex-shrink-0 text-2xs'>
        <select
          className='bg-transparent text-gray-50'
          value={venue.coordinatorPolicyOverride ?? 'none'}
          onChange={(e) => {
            const value = e.target.value;
            if (value === 'none') {
              handleChange({ coordinatorPolicyOverride: null });
            } else {
              handleChange({
                coordinatorPolicyOverride: value as EnumsCoordinatorPolicy,
              });
            }
          }}
        >
          <option className='bg-lp-black-001 text-gray-50' value='none'>
            (none)
          </option>
          <option
            className='bg-lp-black-001 text-gray-50'
            value={EnumsCoordinatorPolicy.CoordinatorPolicyCohost}
          >
            Cohost
          </option>
          <option
            className='bg-lp-black-001 text-gray-50'
            value={EnumsCoordinatorPolicy.CoordinatorPolicyEveryone}
          >
            Everyone
          </option>
          <option
            className='bg-lp-black-001 text-gray-50'
            value={EnumsCoordinatorPolicy.CoordinatorPolicyVenueOwner}
          >
            Venue Owner
          </option>
        </select>
      </div>
    </div>
  );
}
