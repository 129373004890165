import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { firebaseService } from '../../Firebase';
import { useVenueId } from '../../Venue/VenueProvider';
import { FIREBASE_PREFIX, type OnDGameController } from './types';

export function useDeleteOndGameControlRef_DEBUG(svc = firebaseService) {
  const venueId = useVenueId();

  return useLiveCallback(async () => {
    // NOTE(drew): feel free to put more refs + delete in here. Sometimes the
    // venue just gets into a weird state with the controller during local/dev
    // and needs to start fresh.

    const ref = svc.prefixedRef<Nullable<OnDGameController>>(
      `${FIREBASE_PREFIX}/${venueId}/controller`
    );
    await ref.remove();
  });
}
