import { useCallback, useEffect, useState } from 'react';

import { useInstance } from '../../../hooks/useInstance';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { sleep } from '../../../utils/common';
import { PauseIcon } from '../../icons/PauseIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import {
  useRawSoundEffect,
  useSoundEffectAPI,
  useSoundEffectFiles,
} from '../../SFX';
import { SwitcherControlled } from '../../Switcher';

function SFXItem(props: {
  name: string;
  url: string;
  volume: number;
  playable?: boolean;
  desc?: string;
}): JSX.Element {
  const { name, url, volume, playable, desc } = props;
  const [volField, setVolField] = useState(`${volume}`);
  const [error, setError] = useState<unknown | null>(null);
  const [playing, setPlaying] = useState(false);
  const { play: playSFX } = useRawSoundEffect(url, name, volume);
  const mounted = useIsMounted();
  const api = useSoundEffectAPI();

  useEffect(() => {
    setVolField(`${volume}`);
  }, [volume]);

  const play = useCallback(() => {
    setPlaying(true);
    playSFX();
    // We don't know if it's stopped
    setTimeout(() => {
      if (!mounted()) return;
      setPlaying(false);
    }, 1000);
  }, [mounted, playSFX]);

  useEffect(() => {
    if (!playable) return;
    play();
  }, [play, playable]);

  const updateVolume = (val: string) => {
    try {
      const parsed = parseFloat(val);
      if (isNaN(parsed)) {
        setError('wrong number');
      } else {
        api.updateVolume(name as never, parsed);
        setVolField(parsed.toString());
        setError(null);
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className='text-3xs font-light text-gray-200 my-0.5 flex justify-between'>
      <div className='flex flex-col'>
        <div className='text-2xs'>{name}</div>
        <div className='text-icon-gray'>{desc}</div>
      </div>
      <div className='flex items-center ml-1'>
        <input
          type='text'
          className={`bg-transparent text-gray-50 border ${
            error ? ' border-red-001' : 'border-white'
          } text-right px-1 w-10`}
          value={volField}
          onChange={(e) => setVolField(e.target.value)}
          onBlur={(e) => updateVolume(e.target.value)}
        />
        <button
          className='ml-2 border rounded-full p-px'
          onClick={play}
          disabled={playing}
        >
          {playing ? (
            <PauseIcon className='w-2.5 h-2.5' />
          ) : (
            <PlayIcon className='w-2.5 h-2.5' />
          )}
        </button>
      </div>
    </div>
  );
}

function PlayAll(props: { onPlay: () => void; playing: boolean }): JSX.Element {
  const { onPlay, playing } = props;
  return (
    <div className='text-sm font-semibold text-gray-200 my-0.5 flex justify-between border-b pb-1 mb-1'>
      <div>Play All</div>
      <div className='flex items-center'>
        <button
          className='ml-2 border rounded-full p-px'
          onClick={onPlay}
          disabled={playing}
        >
          {playing ? <PauseIcon /> : <PlayIcon />}
        </button>
      </div>
    </div>
  );
}

export function SFXPreview(): JSX.Element | null {
  const [preview, setPreview] = useState(false);
  const [useBaseline, setUseBaseline] = useState(false);
  const [playAll, setPlayAll] = useState(false);
  const [current, setCurrent] = useState<string | null>(null);
  const sfxFiles = useSoundEffectFiles();
  const sfxKeys = useInstance(() => Object.keys(sfxFiles));
  const api = useSoundEffectAPI();

  useEffect(() => {
    if (!useBaseline) return;
    for (const name of sfxKeys) {
      api.updateVolume(name as never, 1);
    }
    return () => api.reset();
  }, [useBaseline, api, sfxKeys]);

  const handleToggle = () => {
    setPreview(!preview);
  };

  const handlePlayAll = async () => {
    setPlayAll(true);
    for (const name of sfxKeys) {
      setCurrent(name);
      await sleep(1200);
    }
    setPlayAll(false);
  };

  return (
    <div>
      <button
        className={`${
          preview ? 'btn-delete' : 'btn-primary'
        } w-full px-10 h-7 text-sms mb-1`}
        onClick={handleToggle}
      >
        {preview ? 'Open Preview' : 'Open Preview'}
      </button>
      {preview && (
        <div className='flex flex-col mt-2'>
          <div className='flex justify-between mb-4'>
            <div>
              <div className='flex items-center text-white text-2xs'>
                Use Baseline
              </div>
              <div className='mt-1 text-3xs text-icon-gray'>
                Play sound effects with baseline volume (1)
              </div>
            </div>

            <div className='flex-shrink-0'>
              <SwitcherControlled
                name='toggle-firebase-connection'
                checked={useBaseline}
                onChange={setUseBaseline}
              />
            </div>
          </div>
          <PlayAll onPlay={handlePlayAll} playing={playAll} />
          <div className='flex flex-col'>
            {Object.entries(sfxFiles).map(([k, v]) => (
              <SFXItem
                key={k}
                name={k}
                url={v.url}
                volume={v.volume}
                playable={k === current}
                desc={v.desc}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
