import { useEffect, useState } from 'react';

import {
  type AutoRecoveryDelay,
  AutoRecoveryDelayOptions,
  useFirebaseContext,
} from '../../Firebase/Context';
import { FirebaseStatus } from '../../Firebase/types';
// eslint-disable-next-line no-restricted-imports
import {
  writeParticipantConnected,
  writeParticipantDisconnected,
} from '../../Player/participantSlice';
import { SwitcherControlled } from '../../Switcher';
import { useMyClientId, useVenueId } from '../../Venue';

export function FirebaseConnection(): JSX.Element {
  const {
    svc,
    status,
    autoRecoveryEnabled,
    setAutoRecoveryEnabled,
    autoRecoveryDelay,
    setAutoRecoveryDelay,
  } = useFirebaseContext();
  const [connected, setConnected] = useState(
    status === FirebaseStatus.Connected
  );

  const [flip, setFlip] = useState(false);

  useEffect(() => {
    if (status === FirebaseStatus.Connected) {
      setConnected(true);
    }
    if (status === FirebaseStatus.Disconnected) {
      setConnected(false);
    }
  }, [status]);

  useEffect(() => {
    if (!flip) return;
    let on = true;
    const timer = setInterval(() => {
      on ? svc.connect() : svc.disconnect();
      on = !on;
    }, 1000);
    return () => clearInterval(timer);
  }, [flip, svc]);

  const toggleConnection = (checked: boolean) => {
    if (checked) {
      svc.connect();
    } else {
      svc.disconnect();
    }
    setConnected(checked);
  };

  const toggleAutoRecovery = (checked: boolean) => {
    setAutoRecoveryEnabled(checked);
  };

  const toggleFlip = (checked: boolean) => {
    setFlip(checked);
  };

  const venueId = useVenueId();
  const clientId = useMyClientId();

  const writeParticipant = async (connected: boolean) => {
    if (connected) await writeParticipantConnected(svc, venueId, clientId);
    else await writeParticipantDisconnected(svc, venueId, clientId);
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex justify-between'>
        <div>
          <div className='flex items-center text-white text-2xs'>
            Auto Recovery
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            Enable/disable Firebase connection auto recovery
          </div>
        </div>

        <div className='flex-shrink-0'>
          <SwitcherControlled
            name='enable-firebase-auto-recovery'
            checked={autoRecoveryEnabled}
            onChange={toggleAutoRecovery}
          />
        </div>
      </div>
      <div className='flex justify-between'>
        <div>
          <div className='flex items-center text-white text-2xs'>
            Disconnect Period (msec)
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            Simulate how long it lasts to disconnect from Firebase
          </div>
        </div>

        <div className='flex-shrink-0 text-2xs'>
          <select
            className='bg-transparent text-gray-50'
            value={autoRecoveryDelay}
            onChange={(e) =>
              setAutoRecoveryDelay(Number(e.target.value) as AutoRecoveryDelay)
            }
          >
            {AutoRecoveryDelayOptions.map((option) => (
              <option
                key={option}
                className='bg-lp-black-001 text-gray-50'
                value={option}
              >
                {option / 1000}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='flex justify-between'>
        <div>
          <div className='flex items-center text-white text-2xs'>
            Toggle Firebase Connection
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            Mark youself connected/disconnected in Firebase
          </div>
        </div>

        <div className='flex-shrink-0'>
          <SwitcherControlled
            name='toggle-firebase-connection'
            checked={connected}
            onChange={toggleConnection}
          />
        </div>
      </div>
      <div className='flex justify-between'>
        <div>
          <div className='flex items-center text-white text-2xs'>
            Flip Firebase Connection (QA only)
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            Flip the Firebase Connection Rapidly
          </div>
        </div>

        <div className='flex-shrink-0'>
          <SwitcherControlled
            name='toggle-firebase-connection'
            checked={flip}
            onChange={toggleFlip}
          />
        </div>
      </div>

      <div className='flex flex-col gap-2'>
        <div>
          <div className='flex items-center text-white text-2xs'>
            Manually Write (DIS)CONNECTED to Firebase
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            If you are connected, write Disconnected to your participant. If
            disconnected locally, write connected.
          </div>
        </div>

        <div className='flex justify-between text-sms h-7'>
          <button
            className='btn-primary px-3'
            type='button'
            onClick={() => writeParticipant(true)}
          >
            Write Connected
          </button>
          <button
            className='btn-delete px-3'
            type='button'
            onClick={() => writeParticipant(false)}
          >
            Write Disconnected
          </button>
        </div>
      </div>
    </div>
  );
}
