import { assertExhaustive } from '@lp-lib/game';

import { type Participant } from '../../types';
import { type CoordinatorSelectPolicy, type MinimumVenue } from './types';

function venueOwnerSelector(venue: MinimumVenue, participants: Participant[]) {
  return participants.find((p) => p.id === venue.uid);
}

function everyoneSelector(participants: Participant[]) {
  return participants.sort((a, b) => a.joinedAt - b.joinedAt)[0];
}

function cohostSelector(participants: Participant[]) {
  return participants
    .filter((p) => p.cohost)
    .sort((a, b) => a.joinedAt - b.joinedAt)[0];
}

export function selectCandidateCoordinator(
  policy: CoordinatorSelectPolicy,
  venue: MinimumVenue,
  participants: Participant[]
): Participant | undefined {
  switch (policy) {
    case 'everyone':
      return everyoneSelector(participants);
    case 'venue-owner':
      return venueOwnerSelector(venue, participants);
    case 'cohost':
      return cohostSelector(participants);
    default:
      assertExhaustive(policy);
      return;
  }
}
