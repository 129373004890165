import { useRef, useState } from 'react';

import {
  EnumsTTSGeneratorId,
  EnumsTTSRenderPolicy,
} from '@lp-lib/api-service-client/public';

import { elevenLabs } from '../../../config/elevenLabs';
import { getEnv } from '../../../config/getEnv';
import { PlayIcon } from '../../icons/PlayIcon';
import { useParticipantsAsArray } from '../../Player';
import {
  AdHocVOMsgSenderProvider,
  useAdHocVOSender,
} from '../../VoiceOver/AdHocVOMsgProviders';
import { type VoiceOverRegistryPlan } from '../../VoiceOver/VoiceOverRegistryPlan';

export function AdHocVoiceOversPanel(): JSX.Element | null {
  return (
    <details className='text-2xs text-white'>
      <summary className='font-bold pb-2'>
        AdHoc Remote VoiceOver Control
      </summary>
      <AdHocVOMsgSenderProvider>
        <AdHocRemoteControl />
      </AdHocVOMsgSenderProvider>
    </details>
  );
}

function AdHocRemoteControl() {
  const config = elevenLabs(getEnv());

  const [groups, setGroups] = useState<VoiceOverRegistryPlan[]>([]);
  const scriptRef = useRef<HTMLTextAreaElement | null>(null);

  // These two sync-ers are just because it wasn't worth building an entire
  // evented api for this debug tool

  return (
    <div className='flex flex-col items-center gap-2'>
      <header>SCRIPTS</header>
      {groups.map((script, idx) => (
        <AHScriptGroup key={idx} plan={script} />
      ))}

      <div className='w-full'>
        <textarea
          ref={scriptRef}
          className='w-full h-20 p-1 bg-secondary'
        ></textarea>
        <button
          type='button'
          className='w-full btn-secondary px-2 py-1'
          onClick={async () => {
            setGroups((gs) => [
              ...gs,
              {
                entries: [
                  {
                    script: scriptRef.current?.value ?? '',
                    ttsRenderSettings: {
                      generatorId: EnumsTTSGeneratorId.TTSGeneratorIdElevenLabs,
                      generatorSettings: {
                        elevenLabs: {
                          voiceId: config.defaultVoiceId,
                          stability: 0.75,
                          similarityBoost: 0.75,
                        },
                      },
                    },
                    policy: EnumsTTSRenderPolicy.TTSRenderPolicyReadThrough,
                  },
                ],
              },
            ]);
          }}
        >
          Add Script
        </button>
      </div>
    </div>
  );
}

function AHScriptGroup(props: { plan: VoiceOverRegistryPlan }) {
  const sender = useAdHocVOSender();

  const everybody = useParticipantsAsArray({
    filters: ['status:connected', 'host:false'],
  });

  const [target, setTarget] = useState<string>('all');

  const onClickPlay = async () => {
    const playIf = target === 'all' ? undefined : { clientIdIn: [target] };

    // Instruct all valid playIfs to play, including, optionally, yourself. NOTE
    // that it's not easy to know when the audio has stopped because it is
    // happening on multiple clients.

    await sender.playScript(props.plan, playIf);
  };

  return (
    <div className='w-full flex justify-between items-center'>
      <button
        type='button'
        className='btn p-0.5 flex justify-center '
        onClick={onClickPlay}
      >
        <PlayIcon />
      </button>
      <div className='w-1/3'>
        {props.plan.entries.map((entry, idx) => (
          <span key={idx}>{'script' in entry ? entry.script : null}</span>
        ))}
      </div>
      <select
        className='bg-secondary'
        onChange={(e) => setTarget(e.currentTarget.value)}
        defaultValue={target}
      >
        <option value='all'>Everyone</option>
        {everybody.map((participant) => (
          <option key={participant.clientId} value={participant.clientId}>
            {participant.firstName ?? participant.username} (
            {participant.clientId.slice(0, 6)}...)
          </option>
        ))}
      </select>
    </div>
  );
}
