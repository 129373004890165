import { useCallback, useEffect, useRef, useState } from 'react';

import { useMyInstance } from '../../hooks/useMyInstance';
import logger from '../../logger/logger';
import { HiddenCanvas } from '../../utils/canvas';
import { rsCounter } from '../../utils/rstats.client';
import { useInfrequentAnimationFrame } from '../CrowdFrames/useInfrequentAnimationFrame';
import { useIsStreamSessionAlive, useStreamSessionId } from '../Session';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { useVenueId } from '../Venue/VenueProvider';
import { createFrameBufferOperator } from './buffer';
import { useSentimentContext } from './Context';
import { isVideoReady } from './helper';
import {
  type BaseProfile,
  type Expression,
  profileFor,
  type ProfileIndex,
  type SortedExpressions,
} from './type';

enum DetectionState {
  Detected,
  Prepared,
  Recording,
  Recorded,
}

type Detection = {
  expressions: SortedExpressions;
  detectedAt: number;
  state: DetectionState;
};

const log = logger.scoped('sentiment');

export function Recorder(props: {
  filmstrip: Filmstrip;
  pause: boolean;
  quality?: number;
  cooldown?: number;
  upload?: boolean;
  uploadLimits?: number;
  baselineCapture?: number;
}): null | JSX.Element {
  const { video, detector, setPreview, uploader } = useSentimentContext();
  const venueId = useVenueId();
  const myClientId = useMyClientId();
  const sessionId = useStreamSessionId();
  const myInstance = useMyInstance();
  const myUserId = myInstance?.id;
  const { filmstrip, pause } = props;
  const cooldown = props.cooldown || 60 * 1000;
  const lastDetectionRef = useRef<Detection | null>(null);
  const enqueuedRef = useRef(0);
  const isSessionAlive = useIsStreamSessionAlive();
  const uploadActive = isSessionAlive && props.upload;
  const uploadLimits = props.uploadLimits || 10;
  const [baselineCapture, setBaselineCapture] = useState(
    props.baselineCapture || 0
  );

  useEffect(() => {
    const off = detector.on('expressions-detected', (expressions) => {
      if (lastDetectionRef.current) {
        return;
      }
      lastDetectionRef.current = {
        expressions,
        detectedAt: Date.now(),
        state: DetectionState.Detected,
      };
      detector.pause(true);
    });

    return () => {
      off();
    };
  }, [detector]);

  useEffect(() => {
    if (!uploadActive) return;
    enqueuedRef.current = 0;
    uploader.start();
    return () => {
      uploader.stop(true);
    };
  }, [uploadActive, uploader]);

  const enqueue = useCallback(
    (profile: BaseProfile, expression: Expression, data: string) => {
      if (!uploadActive) return;
      if (sessionId && myUserId) {
        uploader.enqueue({
          venueId: venueId,
          sessionId: sessionId,
          record: {
            clientId: myClientId,
            userId: myUserId,
            metadata: {
              ...profile,
              expression: expression.expression,
              score: expression.probability,
            },
            data: data,
          },
        });
        enqueuedRef.current++;
        if (enqueuedRef.current >= uploadLimits) {
          // Stop AI capture
          detector.stop('uploadLimitsHit');
        }
        if (enqueuedRef.current >= uploadLimits / 2) {
          // Stop baseline capture
          setBaselineCapture(0);
        }
      }
    },
    [
      myUserId,
      myClientId,
      sessionId,
      uploadActive,
      uploadLimits,
      uploader,
      venueId,
      detector,
    ]
  );

  // Baseline catpure: make sure we can capture at least once regardless the sentiment.
  useEffect(() => {
    if (baselineCapture <= 0 || pause || !isSessionAlive) return;
    const timerId = setInterval(() => {
      // If there is an lastDetection, it means we already captured one sentiment.
      if (lastDetectionRef.current) return;
      lastDetectionRef.current = {
        expressions: [{ expression: 'unknown', probability: 0.01 }],
        detectedAt: Date.now(),
        state: DetectionState.Detected,
      };
      log.info('baseline captured');
    }, baselineCapture);
    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [baselineCapture, detector, isSessionAlive, pause, uploadLimits]);

  useInfrequentAnimationFrame(
    useCallback(async () => {
      rsCounter('sentiment-recorder-poll-ms')?.start();
      await filmstrip.take(video);
      if (lastDetectionRef.current) {
        try {
          if (lastDetectionRef.current.state === DetectionState.Detected) {
            filmstrip.halve();
            lastDetectionRef.current.state = DetectionState.Prepared;
          }
          if (lastDetectionRef.current.state === DetectionState.Prepared) {
            if (filmstrip.isFull()) {
              lastDetectionRef.current.state = DetectionState.Recording;
              const expression = lastDetectionRef.current.expressions[0];
              const data = await filmstrip.print(0.8);
              enqueue(filmstrip.profile, expression, data);
              setPreview({
                expression: expression.expression,
                score: expression.probability,
                data: data,
                profileIndex: filmstrip.profileIndex,
              });
              lastDetectionRef.current.state = DetectionState.Recorded;
            }
          }
          if (lastDetectionRef.current.state === DetectionState.Recorded) {
            if (Date.now() - lastDetectionRef.current.detectedAt >= cooldown) {
              lastDetectionRef.current = null;
              setPreview(null);
              detector.pause(false);
            }
          }
        } catch (error) {
          log.error('recoding failed', error);
          lastDetectionRef.current = null;
          setPreview(null);
          detector.pause(false);
        }
      }
      rsCounter('sentiment-recorder-poll-ms')?.end();
    }, [filmstrip, enqueue, setPreview, cooldown, detector, video]),
    filmstrip.profile.delayMs,
    pause
  );

  return null;
}

export class Filmstrip {
  constructor(
    public readonly profileIndex: ProfileIndex,
    bufferTechnique: 'auto' | 'image-bitmap' | 'canvas',
    willReadFrequently: boolean,
    public readonly profile = profileFor(profileIndex),
    private ocvs = new HiddenCanvas('sentiment-recorder'),
    private cvs = ocvs.cvs,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    private ctx = cvs.getContext('2d', { alpha: false })!,
    private frameBufferOperator = createFrameBufferOperator(
      profile,
      bufferTechnique,
      willReadFrequently
    )
  ) {
    cvs.width = profile.width;
    cvs.height = profile.height * profile.maxStrips;
  }

  close(): void {
    this.frameBufferOperator.close();
    this.ocvs.detach();
  }

  isFull(): boolean {
    return this.frameBufferOperator.isFull();
  }

  halve(): void {
    this.frameBufferOperator.halve();
  }

  async take(source: HTMLVideoElement): Promise<void> {
    if (!isVideoReady(source)) return;
    await this.frameBufferOperator.take(source);
  }

  async print(quality?: number): Promise<string> {
    this.ctx.clearRect(0, 0, this.cvs.width, this.cvs.height);
    let frameIdx = 0;
    const it = this.frameBufferOperator.iterator();
    while (true) {
      const curr = await it.next();
      if (curr.done) {
        break;
      }
      this.ctx.drawImage(
        curr.value,
        0,
        frameIdx * this.profile.height,
        this.profile.width,
        this.profile.height
      );
      frameIdx++;
    }
    return this.cvs.toDataURL('image/jpeg', quality);
  }
}
