import { Canvas2DHelper, HiddenCanvas, ScalePolicy } from '../../utils/canvas';
import { loadCanvasBlobAsPromise, loadImageAsPromise } from '../../utils/media';
import { type Profile } from './type';

interface Disposable {
  close(): void;
}

class DisposableBlob implements Disposable {
  constructor(readonly blob: Blob) {}
  close(): void {
    return;
  }
}

export class FrameBuffer<T extends Disposable> implements Iterable<T> {
  private data: Array<T>;
  constructor(private cap: number) {
    this.data = new Array<T>();
  }
  [Symbol.iterator](): Iterator<T> {
    return this.data[Symbol.iterator]();
  }
  push(item: T): void {
    if (this.data.length >= this.cap) {
      this.dispose(this.data.splice(0, 1));
    }
    this.data.push(item);
  }
  size(): number {
    return this.data.length;
  }
  shift(n: number): void {
    const removed: T[] = [];
    for (let i = 0; i < n; i++) {
      const frame = this.data.shift();
      if (frame) removed.push(frame);
    }
    this.dispose(removed);
  }
  private dispose(items: T[]): void {
    for (let i = 0; i < items.length; i++) {
      // https://developer.mozilla.org/en-US/docs/Web/API/ImageBitmap/close
      // The 'close' is marked not compatible on Safari. Although it's available
      // on version 15.0, add the protection here.
      if ('close' in items[i]) {
        items[i].close();
      }
    }
  }
}

interface FrameBufferOperator extends Disposable {
  take(source: HTMLVideoElement): Promise<void>;
  iterator(): AsyncGenerator<CanvasImageSource>;
  isFull(): boolean;
  halve(): void;
}

abstract class BaseBufferOperator<T extends Disposable>
  implements FrameBufferOperator
{
  protected frames: FrameBuffer<T>;
  constructor(private max: number) {
    this.frames = new FrameBuffer<T>(max);
  }
  abstract take(source: HTMLVideoElement): Promise<void>;
  abstract iterator(): AsyncGenerator<CanvasImageSource>;

  isFull(): boolean {
    return this.frames.size() === this.max;
  }
  halve(): void {
    const len = this.frames.size();
    const n = Math.floor(this.max / 2);
    if (len > n) {
      this.frames.shift(len - n);
    }
  }
  abstract close(): void;
}

class CanvasFrameBufferOperator extends BaseBufferOperator<DisposableBlob> {
  private ocvs = new HiddenCanvas('sentiment-cvs-frame-buffer');
  private cvs: HTMLCanvasElement = this.ocvs.cvs;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  private ctx: CanvasRenderingContext2D = this.cvs.getContext('2d', {
    alpha: false,
    willReadFrequently: this.willReadFrequently,
  })!;
  private ctxHelper = new Canvas2DHelper(this.ctx);
  constructor(profile: Profile, private willReadFrequently: boolean) {
    super(profile.maxStrips);
    this.cvs.width = profile.width;
    this.cvs.height = profile.height;
    this.ocvs.attach();
  }

  async take(source: HTMLVideoElement) {
    this.ctxHelper.drawImage(
      source,
      this.cvs.width,
      this.cvs.height,
      ScalePolicy.ScaleSource
    );
    const blob = await loadCanvasBlobAsPromise(this.cvs, 'image/jpeg');
    if (blob) {
      this.frames.push(new DisposableBlob(blob));
    }
  }
  async *iterator() {
    for (const frame of this.frames) {
      yield await loadImageAsPromise(URL.createObjectURL(frame.blob));
    }
  }
  close() {
    this.ocvs.detach();
  }
}

class ImageBitmapFrameBufferOperator extends BaseBufferOperator<ImageBitmap> {
  private ocvs = new HiddenCanvas('sentiment-ib-frame-buffer');
  private cvs: HTMLCanvasElement = this.ocvs.cvs;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  private ctx: CanvasRenderingContext2D = this.cvs.getContext('2d', {
    alpha: false,
    willReadFrequently: this.willReadFrequently,
  })!;
  private ctxHelper = new Canvas2DHelper(this.ctx);
  constructor(private profile: Profile, private willReadFrequently: boolean) {
    super(profile.maxStrips);
    this.cvs.width = profile.width;
    this.cvs.height = profile.height;
    this.ocvs.attach();
  }
  async take(source: HTMLVideoElement) {
    let imageBitmapSource: ImageBitmapSource = source;
    // Firefox doesn't support resizeWidth and resizeHeight,
    // use the canvas as a workaround. It seems less valuable
    // to use ImageBitmap anymore.
    // https://developer.mozilla.org/en-US/docs/Web/API/createImageBitmap
    if (
      source.videoWidth !== this.profile.width ||
      source.videoHeight !== this.profile.height
    ) {
      this.ctxHelper.drawImage(
        source,
        this.cvs.width,
        this.cvs.height,
        ScalePolicy.ScaleSource
      );
      imageBitmapSource = this.ctx.getImageData(
        0,
        0,
        this.cvs.width,
        this.cvs.height
      );
    }
    const bitmap = await createImageBitmap(
      imageBitmapSource,
      0,
      0,
      this.profile.width,
      this.profile.height
    );
    this.frames.push(bitmap);
  }
  async *iterator() {
    for (const frame of this.frames) {
      yield frame;
    }
  }
  close() {
    this.ocvs.detach();
  }
}

export function createFrameBufferOperator(
  profile: Profile,
  bufferTechnique: 'auto' | 'image-bitmap' | 'canvas',
  willReadFrequently: boolean
): FrameBufferOperator {
  if (
    (bufferTechnique === 'auto' || bufferTechnique === 'image-bitmap') &&
    'createImageBitmap' in window
  ) {
    return new ImageBitmapFrameBufferOperator(profile, willReadFrequently);
  }
  return new CanvasFrameBufferOperator(profile, willReadFrequently);
}
