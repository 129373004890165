import { useCallback, useState } from 'react';

import { isServerValue } from '@lp-lib/firebase-typesafe';
import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useAsyncCall } from '../../../hooks/useAsyncCall';
import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useIsCoordinator } from '../../../hooks/useMyInstance';
import { apiService } from '../../../services/api-service';
import { assertExhaustive, err2s } from '../../../utils/common';
import {
  useGameHostingAPI,
  useGameHostingCoordinator,
} from '../../Game/GameHostingProvider';
import { useIsLiveGamePlay } from '../../Game/hooks';
import {
  CloudHostingUtils,
  type OnDGameController,
  type OnDGameControllerSemaphore,
  useOnDGameControllerSemaphore,
} from '../../Game/OndGameControl';
import { useDebugToolsDeleteOndGameControlRef } from '../../Game/OndGameControl';
import { Loading } from '../../Loading';
import { useOnDCoordinatorSelectPolicy } from '../../OnDGameHosting';
import { useReadOndController } from '../../OnDGameHosting/OnDGameHostingManager';
import { useOndGameUIControl } from '../../OnDGameUIControl';
import { useParticipant } from '../../Player';
import { useRemoteRefresh } from '../../RemoteRefresh/RemoteRefreshProvider';
import { SwitcherControlled } from '../../Switcher';
import { useMyClientId } from '../../Venue/VenuePlaygroundProvider';
import { useVenueId } from '../../Venue/VenueProvider';

// The ControllerSemaphore is not available in host driving game
function useOptionalControllerSemaphore(): OnDGameControllerSemaphore | null {
  try {
    return useOnDGameControllerSemaphore();
  } catch (error) {
    return null;
  }
}

function useDerivedKind(controller: OnDGameController | null): string {
  const kind = controller?.kind;
  if (!controller) return '-';
  if (controller?.status === ConnectionStatus.Disconnected) return 'D';
  // heartbeat timeout
  if (CloudHostingUtils.NoHeartbeat(controller)) return 'T';
  switch (kind) {
    case 'cloud':
      return 'C';
    case 'local':
      return 'L';
    case undefined:
      return '-';
    default:
      assertExhaustive(kind);
      return '-';
  }
}

export function OnDGameControlIndicator(): JSX.Element | null {
  const { controller } = useReadOndController(true);
  const kind = useDerivedKind(controller);

  return (
    <div className='border border-secondary absolute -top-2 -right-2 text-white p-0.75 text-3xs w-5 h-5 bg-secondary rounded-full'>
      {kind}
    </div>
  );
}

function ForceRefreshButton() {
  const refresh = useRemoteRefresh();
  const { controller } = useReadOndController(true);

  return (
    <label className='flex flex-col gap-1'>
      <button
        type='button'
        className={
          'btn-delete w-full px-10 h-7 text-sms flex items-center justify-center'
        }
        onClick={async () => (controller ? await refresh(controller.id) : null)}
      >
        Force Refresh Cloud Instance
      </button>
      <span className='text-3xs text-icon-gray'>
        Cause the Cloud Host to window.reload(). Best used for testing game
        resumption.
      </span>
    </label>
  );
}

function ForceReleaseButton() {
  const venueId = useVenueId();
  const {
    state: { transformed: unbindCallState },
    call: unbind,
  } = useAsyncCall(
    useCallback(async () => {
      await apiService.venue.releaseCloudHosting(venueId);
    }, [venueId])
  );

  return (
    <button
      type='button'
      className={
        'btn-delete w-full px-10 h-7 text-sms mb-1 flex items-center justify-center'
      }
      onClick={unbind}
    >
      {unbindCallState.isRunning && <Loading text='' />}
      <div>Force Release Cloud Instance</div>
    </button>
  );
}

function TakeOverButton(props: { controller: OnDGameController | null }) {
  const { controller } = props;
  const clientId = useMyClientId();
  const isCoordinator = useIsCoordinator();
  const api = useGameHostingAPI();
  const policy = useOnDCoordinatorSelectPolicy(api.log);

  const {
    state: { transformed: state },
    call: takeOver,
  } = useAsyncCall(
    useLiveCallback(async () => {
      await api.register(clientId, {
        asCoordinator: true,
        asController: controller?.kind === 'local',
        force: true,
      });
    })
  );

  return (
    <button
      type='button'
      className={
        'btn-warning w-full px-10 h-7 text-sms mb-1 flex items-center justify-center'
      }
      onClick={takeOver || state.isRunning}
      disabled={isCoordinator || policy === 'venue-owner'}
    >
      {state.isRunning && <Loading text='' />}
      <div>Take Over Coordinator Role</div>
    </button>
  );
}

function UIResetButton() {
  const uiCtrl = useOndGameUIControl();

  return (
    <label className='flex flex-col gap-1'>
      <button
        type='button'
        className={
          'btn-delete w-full px-10 h-7 text-sms flex items-center justify-center'
        }
        onClick={() => uiCtrl?.onClickReset(true)}
      >
        Force Reset Game
      </button>
      <span className='text-3xs text-icon-gray'>
        As if the coordinator clicked Reset(force=true)
      </span>
    </label>
  );
}

function DeleteOndFirebaseRefButton() {
  const hook = useDebugToolsDeleteOndGameControlRef();

  return (
    <label className='flex flex-col gap-1'>
      <button
        type='button'
        className={
          'btn-delete w-full px-10 h-7 text-sms flex items-center justify-center'
        }
        onClick={async () => await hook()}
      >
        Delete Venue ond-game Firebase Ref
      </button>
      <span className='text-3xs text-icon-gray'>
        Best to refresh afterwards.
      </span>
    </label>
  );
}

export function OnDGameControlTools(): JSX.Element | null {
  const isCoordinator = useIsCoordinator();
  const coordinator = useGameHostingCoordinator();
  const controllerSemaphore = useOptionalControllerSemaphore();
  const { controller, error } = useReadOndController(true);
  const pController = useParticipant(controller?.id);
  const pCoordinator = useParticipant(coordinator?.clientId);
  const [watch, setWatch] = useState(true);
  const isLiveGame = useIsLiveGamePlay();
  const formatter = useInstance(
    () =>
      new Intl.DateTimeFormat('en-US', {
        hourCycle: 'h24',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
  );

  const toggleWatch = () => {
    if (watch) {
      controllerSemaphore?.unwatch();
      setWatch(false);
    } else {
      controllerSemaphore?.watch();
      setWatch(true);
    }
  };

  if (isLiveGame) return null;

  return (
    <div className='text-3xs font-light text-gray-200 flex flex-col gap-1'>
      <div className='flex justify-between'>
        <div>Coordinator</div>
        <div>
          {pCoordinator
            ? pCoordinator?.lastName ?? pCoordinator?.username
            : 'Not Available'}
        </div>
      </div>
      {controller && pController ? (
        <>
          <div className='flex justify-between'>
            <div>Controller</div>
            <div>
              {pController.lastName ?? pController?.username} (
              {pController?.id?.slice(0, 8)})
            </div>
          </div>
          <div className='flex justify-between'>
            <div>Type</div>
            <div>{controller.kind}</div>
          </div>
          <div
            className={`flex justify-between ${
              controller.status !== ConnectionStatus.Connected
                ? 'lp-red-002'
                : ''
            }`}
          >
            <div>Status</div>
            <div>{controller.status}</div>
          </div>
          <div className='flex justify-between'>
            <div>Created At</div>
            <div>
              {!isServerValue(controller.acquiredAt) &&
                formatter.format(controller.acquiredAt)}
            </div>
          </div>
          <div className='flex justify-between'>
            <div>Last Heartbeat Received At</div>
            <div>
              {!isServerValue(controller.pingedAt) &&
                formatter.format(controller.pingedAt)}
            </div>
          </div>
          <div className='flex justify-between mb-2'>
            <div>Disconnected At</div>
            <div>
              {controller.disconnectedAt === undefined ||
              isServerValue(controller.disconnectedAt)
                ? 'N/A'
                : formatter.format(controller.disconnectedAt)}
            </div>
          </div>
          {isCoordinator && controllerSemaphore && (
            <div className='flex justify-between mb-2'>
              <div>
                <div className='flex items-center text-white text-2xs'>
                  Receive Controller Heartbeat (From Firebase)
                </div>
                <div className='mt-1 text-3xs text-icon-gray'>
                  start/stop receiving controller heartbeat from Firebase
                </div>
              </div>

              <div className='flex-shrink-0'>
                <SwitcherControlled
                  name='toggle-watch-cloud-controller'
                  checked={watch}
                  onChange={toggleWatch}
                />
              </div>
            </div>
          )}
          {isCoordinator && <ForceReleaseButton />}
        </>
      ) : (
        <div className='flex justify-between'>
          <div>Controller</div>
          <div>Not Available</div>
        </div>
      )}
      <TakeOverButton controller={controller} />
      {Boolean(error) ? (
        <div className='flex justify-between text-red-002'>
          <div>Error</div>
          <div>{err2s(error)}</div>
        </div>
      ) : null}

      <fieldset className='flex flex-col gap-2'>
        <legend className='w-full mb-2 flex justify-center text-3xs font-light text-gray-200'>
          Tools
        </legend>
        <UIResetButton />
        <DeleteOndFirebaseRefButton />
        <ForceRefreshButton />
      </fieldset>
    </div>
  );
}
