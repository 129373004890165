import { useEffect, useRef, useState } from 'react';

import { useHostClientId } from '../../Player';
import { SwitcherControlled } from '../../Switcher';
import { useMyClientId } from '../../Venue/VenuePlaygroundProvider';
import { useRTCService } from '../../WebRTC';

function LevelMeter(props: { reader: () => number }) {
  const readerRef = useRef<typeof props.reader>(props.reader);
  const ref = useRef<HTMLMeterElement | null>(null);
  const [levelDb, setLevelDb] = useState(0);

  useEffect(() => {
    readerRef.current = props.reader;
  });

  useEffect(() => {
    let animRef: number;

    function render() {
      animRef = requestAnimationFrame(render);
      setLevelDb(readerRef.current());
    }

    render();

    return () => {
      cancelAnimationFrame(animRef);
    };
  }, []);

  return (
    <meter
      ref={ref}
      className='w-full'
      min={-100}
      max={6}
      high={2}
      value={levelDb}
    />
  );
}

export function HostAudio(): JSX.Element | null {
  const stage = useRTCService('stage');
  const hostClientId = useHostClientId();
  const myClientId = useMyClientId();

  const [audioBusEnabled, setAudioBusEnabled] = useState(
    () => stage.audioBus.mode === 'process'
  );

  const [reductionRatio, setReductionRatio] = useState(
    stage.audioBus.readParamValue('musicReductionRatio')
  );

  const [sidechain, setSidechain] = useState(
    stage.audioBus.isEffectEnabled('musicSidechain')
  );

  useEffect(() => {
    const offParam = stage.audioBus.on('param-value-changed', (name, value) => {
      if (name === 'musicReductionRatio') setReductionRatio(value);
    });

    const offEffect = stage.audioBus.on('effect-changed', (name, enabled) => {
      if (name === 'musicSidechain') setSidechain(enabled);
    });

    const offMode = stage.audioBus.on('mode-changed', (mode) => {
      setAudioBusEnabled(mode === 'process');
    });

    return () => {
      offParam();
      offMode();
      offEffect();
    };
  }, [stage.audioBus]);

  return myClientId !== hostClientId ? null : (
    <>
      <div className='flex justify-between'>
        <div>
          <div className='flex items-center text-white text-2xs'>
            Host Audio Processing
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            Enable/disable all host audio effects
          </div>
        </div>

        <div className='flex-shrink-0'>
          <SwitcherControlled
            name='audiobus-mode'
            checked={audioBusEnabled}
            onChange={(checked) => {
              stage.audioBus.setMode(checked ? 'process' : 'passthrough');
            }}
          />
        </div>
      </div>

      <div className='flex justify-between'>
        <div>
          <div className='flex items-center text-white text-2xs'>
            Sidechain Background Music
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            Enable/disable mixing the host's voice into the background music bed
            with sidechain compression.
          </div>
        </div>

        <div className='flex-shrink-0'>
          <SwitcherControlled
            name='audiobus-musicsidechain'
            checked={audioBusEnabled && sidechain}
            disabled={!audioBusEnabled}
            onChange={(checked) => {
              stage.audioBus.enableEffect('musicSidechain', checked);
            }}
          />
        </div>
      </div>

      <div>
        <div>
          <div className='flex items-center justify-between text-white text-2xs'>
            <span>Host Sidechain Audio Music Reduction Ratio</span>
            <span>{reductionRatio}</span>
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            How much to reduce the background music when the host speaks (will
            eventually be calculated relative to microphone and music relative
            levels)
          </div>
        </div>

        <input
          className='w-full'
          type='range'
          value={reductionRatio}
          min={0}
          max={2}
          step={0.01}
          onChange={(e) => {
            stage.audioBus.setParamValue(
              'musicReductionRatio',
              Number(e.target.value)
            );
          }}
        />
      </div>

      <div>
        <div>
          <div className='flex items-center justify-between text-white text-2xs'>
            <span>Background Music Reduction Level</span>
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            Amount of reduction being applied to the background music
          </div>
        </div>
        <LevelMeter
          reader={() => stage.audioBus.readParamValue('musicReductionDb')}
        />
      </div>

      <div>
        <div>
          <div className='flex items-center justify-between text-white text-2xs'>
            <span>Microphone Measured Level</span>
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            The inverse level of the Microphone before processing
          </div>
        </div>
        <LevelMeter
          reader={() => stage.audioBus.readParamValue('micLevelDb')}
        />
      </div>

      <div>
        <div>
          <div className='flex items-center justify-between text-white text-2xs'>
            <span>Music Measured Level</span>
          </div>
          <div className='mt-1 text-3xs text-icon-gray'>
            The level of the Music before processing
          </div>
        </div>
        <LevelMeter
          reader={() => stage.audioBus.readParamValue('musicLevelDb')}
        />
      </div>
    </>
  );
}
