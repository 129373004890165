import { useEffect, useMemo, useState } from 'react';

import { getStaticAssetPath } from '../../../utils/assets';
import { uuidv4 } from '../../../utils/common';
import {
  useFirebaseDatabase,
  useIsFirebaseConnected,
} from '../../Firebase/Context';
import { EchoCanceledVideo } from '../../Game/EchoCanceledVideo';
import { useHostClientId } from '../../Player';
import { SwitcherControlled } from '../../Switcher';
import { useMyClientId } from '../../Venue/VenuePlaygroundProvider';
import { useVenueId } from '../../Venue/VenueProvider';

interface Options {
  src?: string;
  aec?: boolean;
  play?: boolean;
}

const testVideoUrl = getStaticAssetPath('videos/BigBuckBunny.mp4');

export function VideoPlayback(): JSX.Element | null {
  const venueId = useVenueId();
  const myClientId = useMyClientId();
  const hostClientId = useHostClientId();
  const isHost = myClientId === hostClientId;
  const firebaseConnected = useIsFirebaseConnected();
  const database = useFirebaseDatabase();
  const ref = useMemo(
    () => database.ref(`debug/${venueId}/video-playback`),
    [database, venueId]
  );
  const [options, setOptions] = useState<Options>({});
  const [source, setSource] = useState(testVideoUrl);
  useEffect(() => {
    if (!firebaseConnected) return;
    async function init() {
      const snapshot = await ref.get();
      const data = snapshot.val();
      if (data) setOptions(data);
      ref.on('value', (snap) => {
        const data = snap.val();
        if (data) setOptions(data);
      });
      if (isHost) {
        await ref.onDisconnect().remove();
      }
    }
    init();
    return () => {
      setOptions({});
      if (isHost) {
        ref.remove();
      }
      ref.off();
    };
  }, [ref, firebaseConnected, isHost]);

  const handleToggle = async () => {
    let value: Options;
    if (options.play) {
      value = { ...options, play: false };
    } else {
      value = { ...options, play: true, src: source };
    }
    setOptions(value);
    await ref.update(value);
  };

  return (
    <div className='flex flex-col w-full'>
      {isHost ? (
        <>
          <div className='text-white flex flex-row mb-2 items-center'>
            <label className='mr-2 text-2xs'>Source: </label>
            <input
              type='text'
              className='bg-transparent text-gray-50 ring-1 ring-white px-1 w-5/6 text-xs disabled:text-gray-500 disabled:cursor-not-allowed'
              value={source}
              disabled={options.play}
              onChange={(e) => setSource(e.target.value)}
            />
          </div>
          <div className='flex justify-between my-2'>
            <div>
              <div className='flex items-center text-white text-2xs'>
                Echo Cancellation
              </div>
              <div className='mt-1 text-3xs text-icon-gray'>
                Echo Cancellation with custom video source may not work if it
                doesn't support CORS.
              </div>
            </div>
            <div className='flex-shrink-0'>
              <SwitcherControlled
                name='video-playback-aec'
                checked={options.aec || false}
                disabled={options.play}
                onChange={(checked) => {
                  setOptions({ ...options, aec: checked });
                }}
              />
            </div>
          </div>
          <div className='mt-2'>
            <button
              type='button'
              className={`${
                options.play ? 'btn-delete' : 'btn-primary'
              } w-full px-10 h-7 text-sms`}
              onClick={handleToggle}
            >
              {options.play ? 'Stop' : 'Play'}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className='text-3xs text-icon-gray'>
            {options.play ? 'Playing' : 'Please play on the host side'}
          </div>
        </>
      )}
      {options.play && options.src && (
        <div className='fixed w-1/2 h-1/2 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          {options.aec ? (
            <EchoCanceledVideo
              mediaId={uuidv4()}
              mediaUrl={options.src}
              containerClassName=''
              className=''
              autoPlay
              debugKey='debug-tools-video-playback'
            />
          ) : (
            <video src={options.src} autoPlay />
          )}
        </div>
      )}
    </div>
  );
}
