import { useNumOfParticipants } from '../../Player';

export function ParticipantTools(): JSX.Element {
  const staffExcluded = useNumOfParticipants({
    filters: ['host:false', 'cohost:false', 'status:connected', 'staff:false'],
  });

  const withStaff = useNumOfParticipants({
    filters: ['host:false', 'cohost:false', 'status:connected'],
  });

  const allConnected = useNumOfParticipants({
    filters: ['status:connected'],
  });

  const oopsAllStaff = useNumOfParticipants({
    filters: ['staff:true', 'status:connected'],
  });

  return (
    <div className='text-3xs font-light text-gray-200 flex flex-col gap-1'>
      <div className='flex justify-between'>
        <div className=''>Participants (-Staff) (-Host)</div>
        <div className=''>{staffExcluded}</div>
      </div>
      <div className='flex justify-between'>
        <div className=''>Participants (+Staff) (-Host)</div>
        <div className=''>{withStaff}</div>
      </div>
      <div className='flex justify-between'>
        <div className=''>Participants (+Staff) (+Host)</div>
        <div className=''>{allConnected}</div>
      </div>
      <div className='flex justify-between'>
        <div className=''>Just Staff</div>
        <div className=''>{oopsAllStaff}</div>
      </div>
    </div>
  );
}
