const MinimizedMenuIcon = (): JSX.Element => {
  return (
    <svg
      className='w-3.5 h-3.5'
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.5 7C1.5 6.44772 1.99249 6 2.6 6H11.4C12.0075 6 12.5 6.44772 12.5 7C12.5 7.55228 12.0075 8 11.4 8H2.6C1.99249 8 1.5 7.55228 1.5 7Z'
        fill='white'
      />
    </svg>
  );
};

export { MinimizedMenuIcon };
