import { useEffect, useRef } from 'react';
import useMeasure from 'react-use-measure';

import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import { ResizeObserver } from '../../utils/ResizeObserver';

function normalize(value: number, min: number, max: number) {
  const mappedValue = value - min;
  const mappedMax = max - min;
  const of1 = mappedValue / mappedMax;
  return of1;
}

const DBFS_MIN = getFeatureQueryParamNumber('host-mixer-min-dbfs');
const DBFS_ALIGNMENT = getFeatureQueryParamNumber('host-mixer-alignment-dbfs');
const DBFS_WARN = getFeatureQueryParamNumber('host-mixer-warn-dbfs');
const DBFS_MAX = getFeatureQueryParamNumber('host-mixer-max-dbfs');

const DBFS_TARGET_MIN = getFeatureQueryParamNumber(
  'host-mixer-target-min-dbfs'
);
const DBFS_TARGET_MAX = getFeatureQueryParamNumber(
  'host-mixer-target-max-dbfs'
);

export function AudioLevelMeter(props: {
  reader: () => number;
  className?: string;
}): JSX.Element {
  const [measureRef, bounds] = useMeasure({
    polyfill: ResizeObserver,
  });

  const PEAK_DECAY_MAX_MS = 1000;
  const PEAK_DECAY_RATE_MS = 10;

  const cvsRef = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const readerRef = useRef<typeof props.reader>(props.reader);
  const peaksRef = useRef({ peak: DBFS_MIN, peakDecayMs: 0 });

  useEffect(() => {
    readerRef.current = props.reader;
  });

  useEffect(() => {
    let animRef: number;

    function fillStyleForDb(db: number) {
      return db <= DBFS_ALIGNMENT
        ? '#2CFF67'
        : db > DBFS_ALIGNMENT && db <= DBFS_WARN
        ? '#FBB707'
        : '#EE3529';
    }

    function render() {
      animRef = requestAnimationFrame(render);

      if (!cvsRef.current) return;
      if (!ctxRef.current) {
        ctxRef.current = cvsRef.current.getContext('2d');
      }

      const ctx = ctxRef.current;
      if (!ctx) return;

      const db = readerRef.current();
      const peak = peaksRef.current.peak;
      if (db > peak || peaksRef.current.peakDecayMs <= 0) {
        peaksRef.current.peak = db;
        peaksRef.current.peakDecayMs = PEAK_DECAY_MAX_MS;
      } else {
        peaksRef.current.peakDecayMs -= PEAK_DECAY_RATE_MS;
        if (peaksRef.current.peakDecayMs <= 0) {
          peaksRef.current.peak = DBFS_MIN;
        }
      }

      const cvsWidth = cvsRef.current.width;
      const cvsHeight = cvsRef.current.height;

      ctx.clearRect(0, 0, cvsWidth, cvsHeight);

      // Somewhat arbitrary: this ratio just looks reasonable given a meter is
      // usually a rectangle.
      const unit = (1 / 5) * cvsWidth;

      const bottomPadding = 0;
      const topPadding = unit;
      const maxBarHeight = cvsHeight - bottomPadding - topPadding;

      {
        // draw signal bar
        const normalized = normalize(db, DBFS_MIN, DBFS_MAX);
        const height = maxBarHeight * normalized;
        const fillStyle = fillStyleForDb(db);
        ctx.fillStyle = fillStyle;
        ctx.fillRect(
          cvsWidth / 2 - unit / 2,
          cvsHeight - bottomPadding,
          unit,
          -height
        );
      }

      {
        // Draw target signal range
        const min = normalize(DBFS_TARGET_MIN, DBFS_MIN, DBFS_MAX);
        const max = normalize(DBFS_TARGET_MAX, DBFS_MIN, DBFS_MAX);
        const heightNormalized = max - min;
        const width = unit * 2;
        ctx.fillStyle = 'rgba(255,255,255,0.15)';
        ctx.fillRect(
          cvsWidth / 2 - width / 2,
          cvsHeight - bottomPadding - min * maxBarHeight,
          width,
          -(heightNormalized * maxBarHeight)
        );
      }

      {
        // Draw guide lines
        const width = 3 * unit;
        const markerLineHeightPx = 2;
        ctx.fillStyle = 'rgba(43, 224, 250, 0.3)';
        // Top max
        ctx.fillRect(
          cvsWidth / 2 - width / 2,
          topPadding,
          width,
          markerLineHeightPx
        );
        // Bottom min
        ctx.fillRect(
          cvsWidth / 2 - width / 2,
          cvsHeight - bottomPadding - markerLineHeightPx,
          width,
          markerLineHeightPx
        );
        // Alignment (Union)
        ctx.fillRect(
          cvsWidth / 2 - width / 2,
          cvsHeight -
            bottomPadding -
            maxBarHeight * normalize(DBFS_ALIGNMENT, DBFS_MIN, DBFS_MAX),
          width,
          markerLineHeightPx
        );
      }

      // Peak
      if (peaksRef.current.peakDecayMs > 0) {
        // Draw Bar
        const peakBarThickness = 6;
        const peakDb = peaksRef.current.peak;
        const fillStyle = fillStyleForDb(peakDb);
        ctx.globalAlpha = peaksRef.current.peakDecayMs / PEAK_DECAY_MAX_MS;
        ctx.fillStyle = fillStyle;
        const peakNormalized = normalize(peakDb, DBFS_MIN, DBFS_MAX);
        const peakHeight = peakNormalized * maxBarHeight;
        ctx.fillRect(
          cvsWidth / 2 - unit / 2,
          cvsHeight - bottomPadding - peakHeight,
          unit,
          peakBarThickness
        );
        ctx.globalAlpha = 1; // reset

        // Draw db value
        const text = `${peakDb.toFixed(0)}`;
        ctx.font = 'bold 9px sans-serif';
        ctx.fillStyle = 'white';
        const measured = ctx.measureText(text);
        ctx.fillText(
          text,
          cvsWidth / 2 - measured.width / 2,
          cvsHeight - bottomPadding - peakHeight - 1
        );
      }
    }

    render();

    return () => {
      cancelAnimationFrame(animRef);
    };
  }, []);

  return (
    <div ref={measureRef} className={`${props.className}`}>
      <canvas ref={cvsRef} width={bounds.width} height={bounds.height} />
    </div>
  );
}
